import { create } from 'zustand';
import { useSpaceStore } from '../../../stores/spaceStore';
import { useNotesAppStore, getNotesAppStore } from 'apps/NotesApp/store/notesAppStore';
import { AnyWindowEntity } from '../../../types/windows';
import { getEntityDisplayName } from '../utils/windowUtils';
import { EventEmitter } from 'events';

interface ContentWindowContext {
  type: string;
  name: string;
  content: string;
}

interface BrowserWindowContext {
  type: 'browser';
  url: string;
  title: string;
}

interface DefaultWindowContext {
  type: string;
  title: string;
}

type WindowContext = ContentWindowContext | BrowserWindowContext | DefaultWindowContext;

interface SpaceSelection {
  text: string;
  source: {
    window: AnyWindowEntity;
    type: string;
  };
}

interface ActiveContext {
  spaceId: string;
  windowId: string;
  window: AnyWindowEntity;
  // This will help us know which store/methods to use
  appType: 'notes' | 'browser' | 'pdf' | null;
}

interface ContextRouterState {
  activeContext: ActiveContext | null;
  currentSelection: SpaceSelection | null;
  contextEntities: AnyWindowEntity[];
  cleanup?: () => void;
  dispose: () => void;

  // Core context management
  setActiveContext: (spaceId: string, windowId: string) => void;
  clearActiveContext: () => void;
  updateContextEntities: () => void;
  setContextEntities: (entities: AnyWindowEntity[]) => void;
  removeContextEntity: (id: string) => void;
  
  // Context-aware actions
  getActiveStore: () => any;
  getText: () => string;
  insertText: (text: string, position: number) => void;
  removeText: (from: number, to: number) => void;
  
  // Selection methods
  setSelection: (selection: SpaceSelection | null) => void;
  getCurrentSelection: () => SpaceSelection | null;
  onSelectionChange: (callback: (selection: SpaceSelection | null) => void) => () => void;
  createDOMSelectionHandler: (window: AnyWindowEntity, type: string) => () => void;
}

// Create a singleton event emitter instance
const selectionEventEmitter = new EventEmitter();

export const useContextRouterStore = create<ContextRouterState>((set, get) => {
  // Set up subscription to spaceStore
  useSpaceStore.subscribe((state, prevState) => {
    if (state.activeWindowId !== prevState.activeWindowId && state.activeWindowId) {
      const spaceId = state.activeSpace?.id;
      if (spaceId) {
        console.log('[ContextRouterStore] Setting active context:', { spaceId, windowId: state.activeWindowId });
        get().setActiveContext(spaceId, state.activeWindowId);
      } else {
        console.warn('[ContextRouterStore] No space ID found');
      }
    }
    
    // Update context entities when windows or active window changes
    if (state.activeWindowId !== prevState.activeWindowId || 
        Object.keys(state.windows).length !== Object.keys(prevState.windows).length) {
      get().updateContextEntities();
    }
  });

  return {
    activeContext: null,
    currentSelection: null,
    contextEntities: [],

    setActiveContext: (spaceId: string, windowId: string) => {
      console.log('[ContextRouterStore] Setting active context:', { spaceId, windowId });
      const spaceStore = useSpaceStore.getState();
      const window = spaceStore.windows[windowId];
      
      if (!window) {
        console.warn('Window not found:', windowId);
        return;
      }

      const appType = window.entityId as ActiveContext['appType'];
      set({
        activeContext: {
          spaceId,
          windowId,
          window,
          appType
        }
      });
      
      // Update context entities when active context changes
      get().updateContextEntities();
    },

    clearActiveContext: () => {
      set({ activeContext: null });
    },
    
    updateContextEntities: () => {
      const spaceStore = useSpaceStore.getState();
      const { activeWindowId, windows } = spaceStore;
      
      const activeWindow = activeWindowId ? windows[activeWindowId] : undefined;
      const selectedWindows = Object.keys(windows).map((id) => windows[id]);
      
      console.log('Context update:', {
        activeWindowId: activeWindowId,
        foundActiveWindow: !!activeWindow,
        activeWindowType: activeWindow?.type,
        numSelectedWindows: selectedWindows.length,
        availableWindowIds: Object.keys(windows)
      });
      
      const newContext = [
        ...(activeWindow ? [activeWindow] : []),
        ...selectedWindows.filter((w) => w.id !== activeWindow?.id),
      ];
      
      set({ contextEntities: newContext });
    },
    
    setContextEntities: (entities: AnyWindowEntity[]) => {
      set({ contextEntities: entities });
    },
    
    removeContextEntity: (id: string) => {
      set(state => ({
        contextEntities: state.contextEntities.filter(entity => entity.id !== id)
      }));
    },

    getActiveStore: () => {
      const { activeContext } = get();
      if (!activeContext) {
        console.log('[ContextRouterStore] No active context found');
        return null;
      }

      console.log('[ContextRouterStore] Getting store for:', {
        appType: activeContext.appType,
        windowId: activeContext.windowId,
        spaceId: activeContext.spaceId
      });

      switch (activeContext.appType) {
        case 'notes': {
          const store = getNotesAppStore(activeContext.windowId, activeContext.spaceId);
          const state = store.getState();
          console.log('[ContextRouterStore] Got notes store state:', {
            hasEditorViewGetCurrentText: typeof state.editorViewGetCurrentText === 'function'
          });
          return state;
        }
        // Add other app types as needed
        default:
          console.log('[ContextRouterStore] Unknown app type:', activeContext.appType);
          return null;
      }
    },

    getText: () => {
      const state = get().getActiveStore();
      console.log('[ContextRouterStore] Getting text from state:', {
        hasEditorViewGetCurrentText: typeof state?.editorViewGetCurrentText === 'function'
      });
      
      if (state?.editorViewGetCurrentText) {
        const text = state.editorViewGetCurrentText();
        console.log('[ContextRouterStore] Got text:', { length: text?.length });
        return text;
      }
      return '';
    },

    insertText: (text: string, position: number) => {
      const store = get().getActiveStore();
      if (store?.editorViewInsertText) {
        store.editorViewInsertText(text, position);
      }
    },

    removeText: (from: number, to: number) => {
      const store = get().getActiveStore();
      if (store?.editorViewRemoveText) {
        store.editorViewRemoveText(from, to);
      }
    },

    // Selection methods
    setSelection: (selection: SpaceSelection | null) => {
      set({ currentSelection: selection });
      selectionEventEmitter.emit('selectionChange', selection);
    },

    getCurrentSelection: () => {
      return get().currentSelection;
    },

    onSelectionChange: (callback: (selection: SpaceSelection | null) => void) => {
      selectionEventEmitter.on('selectionChange', callback);
      return () => {
        selectionEventEmitter.off('selectionChange', callback);
      };
    },

    createDOMSelectionHandler: (window: AnyWindowEntity, type: string) => {
      return () => {
        const selection = document.getSelection();
        if (!selection || !selection.toString().trim()) {
          return;
        }

        get().setSelection({
          text: selection.toString(),
          source: {
            window,
            type,
          }
        });
      };
    },

    initialize: () => {
      // Clean up existing listeners first
      get().dispose();
      
      // Set up new listener if needed
      const cleanup = get().onSelectionChange((selection: SpaceSelection | null) => {
        set({ currentSelection: selection });
      });

      set({ cleanup });
    },

    dispose: () => {
      selectionEventEmitter.removeAllListeners();
    },
  };
});
