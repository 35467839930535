import React from 'react';

import './ChatMessages.scss';
import { ChatMessage } from '../../types';
import { composeContextSummary } from '../../utils/messageUtils';
import { EditProposalDisplay } from '../EditProposal';

interface ChatMessagesProps {
  messages: ChatMessage[];
  activePreview: string | null;
  setActivePreview: (id: string | null) => void;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  isLoading?: boolean;
  mode?: string;
}

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  activePreview,
  setActivePreview,
  messagesEndRef,
  isLoading = false,
  mode = 'chat'
}) => {
  console.log('[ChatMessages] messages:', messages);
  const renderMessageContent = (message: ChatMessage) => {
    if (mode === 'create' && message.contentObject) {
      return (
        <div className="message-content">
          {message.text && <p>{message.text}</p>}
          {message.contentObject.map((action, index) => (
            <div key={index} className="action-result">
              {action.ACTION_NAME && <div className="action-name">{action.ACTION_NAME}</div>}
              {action.response && (
                <div className="action-response">
                  <pre>{JSON.stringify(action.response, null, 2)}</pre>
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }

    if (message.editProposal) {
      return (
        <EditProposalDisplay 
          proposal={message.editProposal}
          onAccept={() => console.log('Accepting edit:', message.editProposal)}
          onReject={() => console.log('Rejecting edit')}
          onPreview={() => setActivePreview(activePreview === message.id ? null : message.id)}
          isStreaming={message.isStreaming}
        />
      );
    }

    if (message.content && message.content.length > 0 && (message.content[0].type === 'tool_use' || message.content[0].type === 'tool_result')) {
      return <div className="message-content" style={{opacity: 0.75}}>
        <div className="message-text">{message.text}</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <code style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {JSON.stringify(message.content)}
          </code>
          <button 
            onClick={() => navigator.clipboard.writeText(JSON.stringify(message.content))}
            style={{ padding: '2px 6px', fontSize: '12px' }}
          >
            Copy
          </button>
        </div>
      </div>;
    }

    return <div className="message-content">{message.text}</div>;
  };

  return (
    <div className="chat-messages">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`message ${message.sender === 'user' ? 'user-message' : 'ai-message'} ${message.content && message.content.length > 0 && (message.content[0].type === 'tool_use' || message.content[0].type === 'tool_result') ? 'tool-message' : ''}`}
        >
          <div className="message-bubble">
            {renderMessageContent(message)}
          </div>
        </div>
      ))}
      
      {isLoading && (
        <div className="message ai-message">
          <div className="message-bubble">
            <div className="message-content loading">
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div ref={messagesEndRef} />
    </div>
  );
}; 