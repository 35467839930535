import { formatDistance } from 'date-fns';
import React from 'react';

import type { UINote } from '../../../types/notes';

interface NotesOverviewProps {
  recentNotes: UINote[];
  onCreateNew: () => void;
  onSelectNote: (id: string) => void;
  show: boolean;
}

export const NotesOverview: React.FC<NotesOverviewProps> = ({ recentNotes, onCreateNew, onSelectNote, show }) => {
  if (!show) return null;
  return (
    <div className="notesOverview">
      <div className="notesOverviewHeader">
        <span className="appTitle">Recent!</span>
        <button onClick={onCreateNew} className="createNoteButton">
          New Note
        </button>
      </div>
      <div className="recentNotesList">
        {recentNotes.length === 0 ? (
          <div className="emptyState">No notes yet. Create your first note!</div>
        ) : (
          recentNotes.map((note) => {
            // Safely handle date formatting
            let timeAgo = 'Unknown time';
            try {
              // const date = note.updated || note.created;
              const date = new Date(note.updated);
              if (date instanceof Date && !isNaN(date.getTime())) {
                timeAgo = formatDistance(date, new Date(), { addSuffix: true });
              }
            } catch (error) {
              console.error('Error formatting date:', error);
            }

            return (
              <div key={note.id} className="noteItem" onClick={() => {
                console.log('[NotesOverview] Selecting note with ID:', note.id);
                onSelectNote(note.id);
              }}>
                <div className="notePreview">
                  <span className="noteTitle">{note.content}</span>
                  <span className="noteTimestamp">{timeAgo}</span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NotesOverview;
