import { useCommandRegistryStore, CommandId, CommandHandler } from "stores/commandRegistryStore";
import { getNotesAppStore } from "./notesAppStore";

// Define the global Command type
export interface Command {
  id: CommandId;
  description: string;
  parameters: {
    name: string;
    type: string;
    description: string;
    required: boolean;
  }[];
  handler: CommandHandler;
}

// Create a registry of all available commands
export const commands: Command[] = [
  {
    id: 'notes_getText',
    description: 'Get the current text content and length from a note',
    parameters: [
      {
        name: 'windowId',
        type: 'string',
        description: 'The ID of the window containing the note (e.g. "window-1741659160271-lk7nur")',
        required: true
      },
      {
        name: 'spaceId',
        type: 'string',
        description: 'The ID of the space containing the note',
        required: true
      }
    ],
    handler: ({windowId, spaceId, callback}: {windowId: string, spaceId: string, callback: (text: string) => void}) => {
      const notesStore = getNotesAppStore(windowId, spaceId);
      const state = notesStore.getState();
      const text = state.editorViewGetCurrentText();
      callback(`{"text": "${text}", "length": ${text.length}}`);
    }
  },
  {
    id: 'notes_insertText',
    description: 'Insert text into a note at the specified position',
    parameters: [
      {
        name: 'windowId',
        type: 'string',
        description: 'The ID of the window containing the note',
        required: true
      },
      {
        name: 'spaceId',
        type: 'string',
        description: 'The ID of the space containing the note',
        required: true
      },
      {
        name: 'text',
        type: 'string',
        description: 'The text to insert',
        required: true
      },
      {
        name: 'position',
        type: 'number',
        description: 'The position at which to insert the text',
        required: true
      }
    ],
    handler: ({windowId, spaceId, text, position, callback}: {windowId: string, spaceId: string, text: string, position: number, callback: (text: string) => void}) => {
      const notesStore = getNotesAppStore(windowId, spaceId);
      const state = notesStore.getState();
      state.showTextDiffModal(text, position, callback);
    }
  },
  {
    id: 'notes_removeText',
    description: 'Remove text from a note between the specified positions',
    parameters: [
      {
        name: 'windowId',
        type: 'string',
        description: 'The ID of the window containing the note',
        required: true
      },
      {
        name: 'spaceId',
        type: 'string',
        description: 'The ID of the space containing the note',
        required: true
      },
      {
        name: 'from',
        type: 'number',
        description: 'The starting position from which to remove text',
        required: true
      },
      {
        name: 'to',
        type: 'number',
        description: 'The ending position up to which to remove text',
        required: true
      }
    ],
    handler: ({windowId, spaceId, from, to, callback}: {windowId: string, spaceId: string, from: number, to: number, callback: (text: string) => void}) => {
      const notesStore = getNotesAppStore(windowId, spaceId);
      const state = notesStore.getState();
      state.editorViewRemoveText(from, to);
      const text = state.editorViewGetCurrentText();
      console.log('[commands][notes_removeText] Text:', text);
      callback(text);
    }
  }
];

// Function to register all commands with the command registry
export const registerNotesCommands = () => {
  const commandRegistry = useCommandRegistryStore.getState();
  
  // Register each command
  commands.forEach(command => {
    commandRegistry.registerCommand(command.id, command.handler);
  });
  
  console.log(`[Commands] Registered ${commands.length} notes commands`);
};

// Function to get commands in a format suitable for Claude tools
export const getCommandsForClaude = () => {
  return commands.map(command => ({
    name: command.id,
    description: command.description,
    input_schema: {
      type: "object",
      properties: Object.fromEntries(
        command.parameters.map(param => [
          param.name,
          {
            type: param.type,
            description: param.description
          }
        ])
      ),
      required: command.parameters
        .filter(param => param.required)
        .map(param => param.name)
    }
  }));
};

export default registerNotesCommands;