import './BuyGalacticaModal.scss';

import React from 'react';

import ModalWrapper from '../../../components/ModalWrapper';

interface BuyGalacticaModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const BuyGalacticaModal: React.FC<BuyGalacticaModalProps> = ({
    isOpen,
    onClose,
}) => {
    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            title="Buy Galactica I"
        >
            <div className="buy-galactica-modal">
                {/* Placeholder content - we'll fill this in later */}
                <p>Coming soon: Purchase your Galactica I device</p>
                <button onClick={onClose}>Close</button>
            </div>
        </ModalWrapper>
    );
};

export default BuyGalacticaModal; 