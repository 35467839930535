import { SpaceEntity, UISpace } from '../types/spaces';
import { AnyWindowEntity } from '../types/windows';
import fetchService from './fetchService';
import { API_BASE_URL } from '../api/config';

export class SpaceService {
  private baseUrl = `${API_BASE_URL}/spaces`;

  private getAuthHeaders() {
    const authToken = localStorage.getItem('authToken');
    return {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };
  }

  async fetchSpace(id: string): Promise<UISpace> {
    const space: SpaceEntity = await fetchService(`${this.baseUrl}/${id}`, {
      credentials: 'include',
    });
    return this.toUISpace(space);
  }

  async updateSpace(id: string, windows: AnyWindowEntity[], settings: any): Promise<UISpace> {
    const space: SpaceEntity = await fetchService(`${this.baseUrl}/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ windows, settings }),
      credentials: 'include',
    });
    return this.toUISpace(space);
  }

  private toUISpace(space: SpaceEntity): UISpace {
    console.log('[SpaceService] Converting space:', space);
    return {
      id: space._id,
      name: space.name,
      windows: space.skeleton.windows,
      settings: space.skeleton.settings,
      created: new Date(space.created_at),
      updated: new Date(space.updated_at),
    };
  }

  async createSpace(name: string): Promise<UISpace> {
    const response = await fetch(`${this.baseUrl}`, {
      method: 'POST',
      headers: this.getAuthHeaders(),
      body: JSON.stringify({ name }),
      credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to create space');
    const space: SpaceEntity = await response.json();
    return this.toUISpace(space);
  }

  async getSpace(id: string): Promise<UISpace | null> {
    try {
      return await this.fetchSpace(id);
    } catch (error) {
      if (error instanceof Error && error.message.includes('404')) {
        return null;
      }
      throw error;
    }
  }

  // async updateWindow(windowId: string, updates: Partial<AnyWindowEntity>) {
  //   const space = await this.fetchSpace(windowId.split('-')[0]);
  //   const updatedWindows = space.windows.map((w) =>
  //     w.id === windowId ? ({ ...w, ...updates } as AnyWindowEntity) : w,
  //   );
  //   return this.updateSpace(space.id, updatedWindows as AnyWindowEntity[], space.settings);
  // }

  // TODO: Remove when updates are implemented
  async groupWindows(windowIds: string[], spaceId: string) {
    if (windowIds.length < 2) return;

    // try {
    //   const space = await this.fetchSpace(spaceId);
    //   const firstWindow = space.windows.find(w => w.id === windowIds[0]);
    //   if (!firstWindow) return;

    //   // Convert first window to tabbed window
    //   const updatedWindow: WindowEntity = {
    //     ...firstWindow,
    //     tabs: windowIds,
    //     activeTabId: windowIds[0],
    //     isParentWindow: true
    //   };

    //   // Update windows array
    //   const updatedWindows = space.windows.map(win =>
    //     win.id === firstWindow.id ? updatedWindow : win
    //   );

    //   // Update via CRDT first
    //   spaceCRDTService.updateWindows(spaceId, updatedWindows);

    //   // Then persist to database
    //   return await this.updateSpace(space.id, updatedWindows, space.settings);
    // } catch (error) {
    //   console.error('Error grouping windows:', error);
    //   throw new Error('Failed to group windows');
    // }
  }

  // TODO: Remove when updates are implemented
  async ungroupWindows(windowId: string, spaceId: string) {
    try {
      // const space = await this.fetchSpace(spaceId);
      // const window = space.windows.find(w => w.id === windowId) as WindowEntity;
      // if (!window?.tabs) return;
      // // Remove tab properties
      // const updatedWindow = {
      //   ...window,
      //   tabs: undefined,
      //   activeTabId: undefined,
      //   isParentWindow: undefined
      // };
      // const updatedWindows = space.windows.map(win =>
      //   win.id === windowId ? updatedWindow : win
      // );
      // // Update CRDT
      // spaceCRDTService.updateWindows(spaceId, updatedWindows);
      // return this.updateSpace(space.id, updatedWindows, space.settings);
    } catch (error) {
      console.error('Error ungrouping windows:', error);
      throw new Error('Failed to ungroup windows');
    }
  }

  async fetchRecentSpaces(): Promise<UISpace[]> {
    const response = await fetch(`${this.baseUrl}/recent`, {
      headers: this.getAuthHeaders(),
      credentials: 'include',
    });

    if (!response.ok) throw new Error('Failed to fetch spaces');

    const spaces: UISpace[] = await response.json();
    console.log('[SpaceService] fetchedRecentSpaces', spaces);
    // return spaces.map(space => this.toUISpace(space));
    return spaces;
  }

  async updateSpaceName(spaceId: string, newName: string) {
    // console.log('[SpaceService] Updating name:', { spaceId, newName });
    try {
      // Update in backend
      const response = await fetch(`${this.baseUrl}/${spaceId}`, {
        method: 'PATCH',
        headers: {
          ...this.getAuthHeaders(),
        },
        body: JSON.stringify({ name: newName }), // Just send name at top level
        credentials: 'include',
      });

      if (!response.ok) {
        const error = await response.text();
        console.error('Server error:', error);
        throw new Error(error);
      }

      return true;
    } catch (error) {
      console.error('Failed to update space name:', error);
      throw error;
    }
  }
}

export const spaceService = new SpaceService();
