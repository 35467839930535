import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

import { presenceCRDTService } from '../services/crdt/presenceCRDTService';

export interface CursorData {
    x: number;
    y: number;
    userId?: string;
    username?: string;
    color?: string;
}

interface PresenceState {
    cursors: Record<string, CursorData | null>;
    clientId: string;
    spaceId: string | null;
    status: 'idle' | 'loading' | 'error';
    error?: Error;
}

interface PresenceStore extends PresenceState {
    setCursor: (x: number, y: number) => void;
    cleanup: () => void;
    initializePresence: (spaceId: string) => Promise<(() => void) | void>;
}

const usePresenceStore = create<PresenceStore>((set, get) => ({
    cursors: {},
    clientId: uuidv4().slice(0, 8),
    spaceId: null,
    status: 'idle',
    
    initializePresence: async (spaceId: string) => {
        set({ status: 'loading', spaceId });
        try {
            const cleanup = await presenceCRDTService.observePresence(spaceId, (cursors) => {
                set({ cursors });
            });
            set({ status: 'idle' });
            return cleanup;
        } catch (error) {
            set({ status: 'error', error: error as Error });
            throw error;
        }
    },

    setCursor: (x, y) => {
        const { clientId, spaceId } = get();
        if (!spaceId) return;

        const presenceData = {
            x,
            y,
            userId: clientId,
            username: `User-${clientId.slice(0, 4)}`,
            color: '#ff0000'
        };
        
        presenceCRDTService.updatePresence(spaceId, clientId, presenceData);
        set(state => ({
            cursors: {
                ...state.cursors,
                [clientId]: presenceData
            }
        }));
    },

    cleanup: () => {
        const { clientId, spaceId } = get();
        if (!spaceId) return;

        presenceCRDTService.updatePresence(spaceId, clientId, null);
        set(state => ({
            cursors: {
                ...state.cursors,
                [clientId]: null
            }
        }));
    }
}));

export default usePresenceStore;
