import SettingsApp from 'apps/SettingsApp/SettingsApp';
import WebDocumentApp from 'apps/WebDocumentApp/WebDocumentApp';
import React from 'react';
import { create } from "zustand";

import BrowserApp from '../apps/BrowserApp/BrowserApp';
// Import all possible app components statically
import EntityBrowserApp from "../apps/EntityBrowserApp/EntityBrowserApp";
import { ImageViewerApp } from '../apps/ImageViewerApp/ImageViewerApp';
import NotesApp from "../apps/NotesApp/components/NotesApp";
import { PDFiumApp } from "../apps/PDFiumApp/PDFiumApp";
import { appRegistry } from '../services/appRegistryService';
import type { AppDefinition, StoredAppDefinition } from '../types/apps';
// Static component map - use consistent appIds
const componentMap: Record<string, React.ComponentType<any>> = {
  pdfium: PDFiumApp,
  notes: NotesApp,
  entityBrowser: EntityBrowserApp,
  webdocument: WebDocumentApp,
  settings: SettingsApp,
  browser: BrowserApp,
  imageviewer: ImageViewerApp,
};

type AppInfo = {
  appId: string;
  name: string;
  icon?: string;
  description?: string;
  requiresProps?: boolean;
  requiresEntity?: boolean;
};

interface AppRegistryState {
  apps: Record<string, StoredAppDefinition>;
  componentMap: Record<string, React.ComponentType<any>>;
  initializeApps: () => Promise<void>;
}

export const useAppRegistryStore = create<AppRegistryState>((set) => ({
  apps: {},
  componentMap,
  initializeApps: async () => {
    try {
      const installedApps = await appRegistry.getInstalledApps();

      const formattedApps = installedApps.reduce<Record<string, StoredAppDefinition>>((acc, app: AppDefinition) => {
        acc[app.appId] = {
          _id: '', // Required by StoredAppDefinition
          entityType: 'App',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          skeleton: app,
        };
        return acc;
      }, {});

      set({ apps: formattedApps });
    } catch (error) {
      console.error('Failed to initialize apps:', error);
    }
  },
}));
