import { create } from 'zustand';
import { CLAUDE_ENDPOINTS } from '../../../endpoints';
import { ChatMessage, ChatMode, Thread, ThreadSummary } from './types';
import { AnyWindowEntity } from '../../../types/windows';
import { AppAction } from '../../../types';
import { resolvePromisesSequentially } from 'utils/callbackUtils';
import { callCustomEventWithReply, getIntroSystemMessage, parseAppActionsString } from '../../../services/actionService';
import fetchService from '../../../services/fetchService';
import { AI_CHAT_ENDPOINTS } from '../../../endpoints';
import { getCommandsForClaude } from 'apps/NotesApp/store/commands';

// Helper function to generate message IDs
const generateMessageId = (() => {
  let counter = 0;
  return () => {
    counter += 1;
    return `msg-${Date.now()}-${counter}`;
  };
})();

interface Mention {
  id: string;
  displayName: string;
  type: string;
}

// Stream response types
interface StreamTextData {
  type: 'text';
  content: string;
}

interface StreamToolUseData {
  type: 'tool_use';
  content: {
    id: string;
    name: string;
    input: any;
  };
}

interface StreamMessageStartData {
  type: 'message_start';
  message_id: string;
}

interface StreamContentBlockStartData {
  type: 'content_block_start';
  index: number;
}

interface StreamContentBlockStopData {
  type: 'content_block_stop';
  index: number;
}

interface StreamMessageDeltaData {
  type: 'message_delta';
  delta: {
    stop_reason?: string;
  };
}

interface StreamMessageStopData {
  type: 'message_stop';
}

interface StreamPingData {
  type: 'ping';
}

interface StreamDoneData {
  type: 'done';
}

interface StreamErrorData {
  type: 'error';
  message: string;
}

type StreamData = 
  | StreamTextData
  | StreamToolUseData
  | StreamMessageStartData
  | StreamContentBlockStartData
  | StreamContentBlockStopData
  | StreamMessageDeltaData
  | StreamMessageStopData
  | StreamPingData
  | StreamDoneData
  | StreamErrorData;

interface ParseStreamOptions {
  reader: ReadableStreamDefaultReader<Uint8Array>;
  aiMessageId: string;
  logPrefix: string;
  onText: (text: string, fullResponse: string) => void;
  onToolUse?: (toolData: StreamToolUseData['content']) => void;
  onDone: (fullResponse: string) => void;
  onError?: (error: Error) => void;
}

/**
 * Parses a stream from Claude API and handles different event types
 */
const parseStream = async ({
  reader,
  aiMessageId,
  logPrefix,
  onText,
  onToolUse,
  onDone,
  onError
}: ParseStreamOptions): Promise<{ fullResponse: string; toolMessage: any | null }> => {
  let fullResponse = '';
  let toolMessage: any = null;
  
  try {
    // Process the stream
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      // Convert the chunk to text
      const chunk = new TextDecoder().decode(value);
      const lines = chunk.split('\n').filter(line => line.trim());
      
      console.log(`[spaceChatStore][${logPrefix}] lines: `, lines);
      
      for (const line of lines) {
        try {
          const data = JSON.parse(line) as StreamData;
          
          switch (data.type) {
            case 'text':
              // Update streaming text
              fullResponse += data.content;
              onText(data.content, fullResponse);
              break;
              
            case 'tool_use':
              // Handle tool use
              console.log(`[spaceChatStore][${logPrefix}] Tool use requested:`, data.content);
              toolMessage = data.content;
              
              if (onToolUse) {
                onToolUse(data.content);
                // Break the stream processing loop
                reader.cancel();
                return { fullResponse, toolMessage };
              }
              break;
              
            case 'message_start':
              // Message start event
              console.log(`[spaceChatStore][${logPrefix}] Message start:`, data.message_id);
              break;
              
            case 'content_block_start':
              // Content block start event
              console.log(`[spaceChatStore][${logPrefix}] Content block start:`, data.index);
              break;
              
            case 'content_block_stop':
              // Content block stop event
              console.log(`[spaceChatStore][${logPrefix}] Content block stop:`, data.index);
              break;
              
            case 'message_delta':
              // Message delta event
              console.log(`[spaceChatStore][${logPrefix}] Message delta:`, data.delta);
              if (data.delta.stop_reason === 'tool_use') {
                console.log(`[spaceChatStore][${logPrefix}] Tool use stop reason:`, data.delta);
              }
              break;
              
            case 'message_stop':
              // Message stop event
              console.log(`[spaceChatStore][${logPrefix}] Message stop`);
              break;
              
            case 'ping':
              // Ping event - just log it
              console.log(`[spaceChatStore][${logPrefix}] Ping received`);
              break;
              
            case 'done':
              // Stream is complete
              console.log(`[spaceChatStore][${logPrefix}] Stream complete:`, data);
              onDone(fullResponse);
              return { fullResponse, toolMessage };
              
            case 'error':
              throw new Error(data.message || 'Unknown error in stream');
              
            default:
              console.log(`[spaceChatStore][${logPrefix}] Unknown event type:`, data);
          }
        } catch (error) {
          console.error(`[spaceChatStore][${logPrefix}] Error processing stream chunk:`, error, line);
          if (onError) {
            onError(error instanceof Error ? error : new Error(String(error)));
          }
        }
      }
    }
  } catch (error) {
    console.error(`[spaceChatStore][${logPrefix}] Error processing stream:`, error);
    if (onError) {
      onError(error instanceof Error ? error : new Error(String(error)));
    }
  }
  
  return { fullResponse, toolMessage };
};

interface SpaceChatState {
  // Message state
  messages: ChatMessage[];
  isProcessing: boolean;
  isStreaming: boolean;
  streamingText: string;
  inputText: string;
  
  // Thread state
  threads: Record<string, Thread>;
  currentThreadIds: Record<ChatMode, string>;
  
  // Mention state
  mentionSearch: string;
  mentionSuggestions: AnyWindowEntity[];
  mentions: Mention[];
  mentionStartIndex: number;
  mentionResults: AnyWindowEntity[];
  showMentionResults: boolean;
  
  // Chat mode and model
  mode: ChatMode;
  model: string;
  
  // Context and tools
  isContextVisible: boolean;
  isActionsVisible: boolean;
  activeToolId: string | null;
  activeToolPayload: {
    id: string;
    input: any;
    name: string;
  } | null;
  conversationHistory: any[];
  nextAutoMessage: AppAction[] | null;
  
  // UI state
  isHistoryOpen: boolean;
  activePreview: string | null;
  
  // Actions
  setInputText: (text: string) => void;
  setMessages: (updater: ChatMessage[] | ((prev: ChatMessage[]) => ChatMessage[])) => void;
  addMessage: (message: ChatMessage) => void;
  clearMessages: () => void;
  
  // Thread actions
  createThread: (mode: ChatMode) => string;
  addMessageToThread: (mode: ChatMode, message: ChatMessage) => void;
  getCurrentThread: (mode: ChatMode) => Thread | null;
  getThreadHistory: (mode: ChatMode) => ThreadSummary[];
  switchToThread: (threadId: string) => void;
  
  // Message sending
  sendMessage: (text: string, context: any) => Promise<void>;
  sendMessageHistory: (text: string) => Promise<void>;
  submitToolOutput: (toolId: string, output: any, history: ChatMessage[]) => Promise<any>;
  
  // Mention handling
  setMentionSearch: (search: string) => void;
  setMentionSuggestions: (suggestions: AnyWindowEntity[]) => void;
  setMentions: (mentions: Mention[]) => void;
  setMentionStartIndex: (index: number) => void;
  setMentionResults: (results: AnyWindowEntity[]) => void;
  setShowMentionResults: (show: boolean) => void;
  handleMentionSelect: (entity: AnyWindowEntity) => void;
  
  // UI actions
  setMode: (mode: ChatMode) => void;
  setModel: (model: string) => void;
  setIsHistoryOpen: (isOpen: boolean) => void;
  setActivePreview: (previewId: string | null) => void;
  setIsContextVisible: (isVisible: boolean) => void;
  setIsActionsVisible: (isVisible: boolean) => void;
  setNextAutoMessage: (message: AppAction[] | null) => void;
}

export const useSpaceChatStore = create<SpaceChatState>((set, get) => ({
  // Message state
  messages: [],
  isProcessing: false,
  isStreaming: false,
  streamingText: '',
  inputText: '',
  
  // Thread state
  threads: {},
  currentThreadIds: {
    create: '',
    chat: '',
    editor: '',
    exp1: ''
  },
  
  // Mention state
  mentionSearch: '',
  mentionSuggestions: [],
  mentions: [],
  mentionStartIndex: -1,
  mentionResults: [],
  showMentionResults: false,
  
  // Chat mode and model
  mode: 'create' as ChatMode,
  model: 'claude-3.5-sonnet',
  
  // Context and tools
  isContextVisible: true,
  isActionsVisible: false,
  activeToolId: null,
  activeToolPayload: null,
  conversationHistory: [],
  nextAutoMessage: null,
  
  // UI state
  isHistoryOpen: false,
  activePreview: null,
  
  // Actions
  setInputText: (text) => set({ inputText: text }),
  
  setMessages: (updater) => set((state) => {
    if (typeof updater === 'function') {
      return { messages: updater(state.messages) };
    }
    return { messages: updater };
  }),
  
  addMessage: (message) => set((state) => ({
    messages: [...state.messages, message]
  })),
  
  clearMessages: () => set({ messages: [] }),
  
  // Thread actions
  createThread: (mode) => {
    const threadId = `thread-${Date.now()}`;
    const newThread: Thread = {
      id: threadId,
      mode,
      title: `New ${mode} thread`,
      messages: [],
      createdAt: new Date(),
      lastActive: new Date()
    };

    set((state) => ({
      threads: {
        ...state.threads,
        [threadId]: newThread
      },
      currentThreadIds: {
        ...state.currentThreadIds,
        [mode]: threadId
      }
    }));

    return threadId;
  },
  
  addMessageToThread: (mode, message) => {
    const state = get();
    let threadId = state.currentThreadIds[mode];
    
    // Create new thread if none exists
    if (!threadId || !state.threads[threadId]) {
      threadId = get().createThread(mode);
    }

    set((state) => ({
      threads: {
        ...state.threads,
        [threadId]: {
          ...state.threads[threadId],
          messages: [...state.threads[threadId].messages, message],
          lastActive: new Date(),
          // Update title based on first message if not set
          title: state.threads[threadId].messages.length === 0 ? 
            message.text?.slice(0, 30) || state.threads[threadId].title : 
            state.threads[threadId].title
        }
      }
    }));
  },
  
  getCurrentThread: (mode) => {
    const state = get();
    const threadId = state.currentThreadIds[mode];
    return threadId ? state.threads[threadId] : null;
  },
  
  getThreadHistory: (mode) => {
    const { threads } = get();
    return Object.values(threads)
      .filter(thread => thread.mode === mode)
      .map(thread => ({
        id: thread.id,
        mode: thread.mode,
        title: thread.title,
        messageCount: thread.messages.length,
        lastActive: thread.lastActive
      }))
      .sort((a, b) => b.lastActive.getTime() - a.lastActive.getTime());
  },
  
  switchToThread: (threadId) => {
    const { threads } = get();
    const thread = threads[threadId];
    if (thread) {
      set((state) => ({
        currentThreadIds: {
          ...state.currentThreadIds,
          [thread.mode]: threadId
        }
      }));
    }
  },
  
  // Message sending
  sendMessage: async (text, context) => {
    if (!text.trim() || get().isProcessing) return;

    set({ isProcessing: true });

    try {
      // Add user message
      const userMessageId = generateMessageId();
      set((state) => ({
        messages: [
          ...state.messages,
          {
            id: userMessageId,
            text,
            sender: 'user',
            role: 'user',
            timestamp: new Date(),
          }
        ]
      }));

      // Get context from the component that uses this store
      // This will be passed in from the component
      // const context = {}; // This will be provided by the component

      // Get conversation history
      const updatedHistory = [
        ...get().conversationHistory,
        {
          id: generateMessageId(),
          text,
          sender: 'user',
          role: 'user',
          content: text
        },
      ];

      set({ conversationHistory: updatedHistory });

      // Create a new AbortController
      const controller = new AbortController();
      const { signal } = controller;


      // TODO: this is a hack, fix later -- we have messages and conversationHistory
      const normalizedHistory = updatedHistory.map((msg) => ({
        role: msg.sender === 'user' ? 'user' : msg.sender === 'ai' ? 'assistant' : msg.role,
        content: msg.content || msg.sender === 'user' ? msg.text : msg.content
      })).filter(msg => !!msg.content)

      // Create a reader for the stream
      const response = await fetch(CLAUDE_ENDPOINTS.chatStream(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          query: text,
          context,
          conversation_history: normalizedHistory,
          tools: getCommandsForClaude()
        }),
        signal
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Failed to process query');
      }

      // Get the reader from the response body
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Failed to get response reader');
      }

      // Create initial AI message
      const aiMessageId = generateMessageId();
      set((state) => ({
        messages: [
          ...state.messages,
          {
            id: aiMessageId,
            text: '',
            sender: 'ai',
            timestamp: new Date(),
            isStreaming: true
          },
        ],
        isStreaming: true
      }));

      // Use the parseStream function to process the stream
      const { fullResponse, toolMessage } = await parseStream({
        reader,
        aiMessageId,
        logPrefix: 'sendMessage',
        onText: (text, fullResponse) => {
          set({ streamingText: fullResponse });
          
          // Update the AI message
          set((state) => {
            const updatedMessages = [...state.messages];
            const aiMessageIndex = updatedMessages.findIndex(msg => msg.id === aiMessageId);
            if (aiMessageIndex !== -1) {
              updatedMessages[aiMessageIndex] = {
                ...updatedMessages[aiMessageIndex],
                text: fullResponse
              };
            }
            return { messages: updatedMessages };
          });
        },
        onToolUse: (toolData) => {
          // This will be handled by the component that uses this store
          console.log('[spaceChatStore][sendMessage] Tool use requested:', toolData);
        },
        onDone: (fullResponse) => {
          // Add the assistant message to conversation history
          const assistantHistoryMessage = {
            role: 'assistant',
            content: fullResponse
          };
          
          set((state) => ({ 
            conversationHistory: [...updatedHistory, assistantHistoryMessage]
          }));
        },
        onError: (error) => {
          console.error('[spaceChatStore][sendMessage] Error in stream:', error);
        }
      });

      // Update the final AI message
      set((state) => {
        const updatedMessages = [...state.messages];
        const aiMessageIndex = updatedMessages.findIndex(msg => msg.id === aiMessageId);
        if (aiMessageIndex !== -1) {
          updatedMessages[aiMessageIndex] = {
            ...updatedMessages[aiMessageIndex],
            text: fullResponse,
            sender: 'ai',
            role: 'assistant',
            content: fullResponse,
            isStreaming: false
          };
        }

        if (toolMessage) {
          updatedMessages.push({
            id: generateMessageId(),
            text: "Requesting tool usage...",
            sender: 'ai',
            role: 'assistant',
            content: [{
              type: 'tool_use',
              ...toolMessage,
            }],
            timestamp: new Date()
          });
        }

        return {
          activeToolId: toolMessage?.id,
          activeToolPayload: toolMessage,
          messages: updatedMessages,
        };
        
      });

    } catch (error) {
      console.error('[spaceChatStore][sendMessage] Error processing message:', error);

      // Add error message
      set((state) => ({
        messages: [
          ...state.messages,
          {
            id: generateMessageId(),
            text: `Error: ${error instanceof Error ? error.message : 'Unknown error'}`,
            sender: 'ai',
            timestamp: new Date(),
          },
        ]
      }));
    } finally {
      set({ 
        isProcessing: false,
        isStreaming: false,
        inputText: ''
      });
    }
  },
  
  sendMessageHistory: async (text) => {
    if (!text.trim() || get().isProcessing) return;

    set({ isProcessing: true });

    // Create a properly typed user message
    const userMessage: ChatMessage = {
      id: generateMessageId(),
      text: text,
      sender: 'user',
      timestamp: new Date(),
    };

    set((state) => ({
      messages: [...state.messages, userMessage]
    }));

    // This context will be provided by the component
    const context = {
      activeWindow: {
        type: 'notes',
        name: 'Notes',
        content: '', // This will be provided by the component
      },
      selectedWindows: [],
      selection: '', // This will be provided by the component
    };

    const payloadMessages = [
      {
        role: 'system',
        content: getIntroSystemMessage(),
      } as ChatMessage,
      ...get().messages,
      userMessage,
    ];

    try {
      const { content }: { content: string } = await fetchService(AI_CHAT_ENDPOINTS.sendMessage(), {
        method: 'POST',
        body: JSON.stringify({ 
          messages: payloadMessages,
          context
        }),
      });

      // Create a properly typed AI message
      const aiMessage: ChatMessage = {
        id: generateMessageId(),
        text: content,
        sender: 'ai',
        timestamp: new Date(),
      };

      set((state) => ({
        messages: [...state.messages, aiMessage]
      }));

      const appActions = parseAppActionsString(content);

      if (appActions) {
        const replies: (AppAction | null)[] = await resolvePromisesSequentially(
          appActions.map(async (appAction) => {
            // this await makes sure that similar actions will be separated from each other
            await new Promise((resolve) => setTimeout(resolve, 0));

            const actionReply = (await callCustomEventWithReply(appAction)) as any;

            if (actionReply !== undefined) {
              return {
                ...appAction,
                response: actionReply,
              };
            }

            return null;
          }),
        );

        const validReplies = replies.filter(Boolean) as AppAction[];

        if (validReplies?.length) {
          set({ nextAutoMessage: validReplies });
        }
      }
    } catch (error: any) {
      // Create a properly typed error message
      const errorMessage: ChatMessage = {
        id: generateMessageId(),
        text: error.message || error.toString() || 'Sorry, I encountered an error',
        sender: 'ai',
        timestamp: new Date(),
      };

      set((state) => ({
        messages: [...state.messages, errorMessage]
      }));
    } finally {
      set({ isProcessing: false });
    }
  },
  
  submitToolOutput: async (toolId, output, history) => {
    // TODO: yelds error when output is empty
    try {
      console.log('[spaceChatStore][submitToolOutput] Submitting tool output:', { toolId, output });
      
      // Find the last assistant message in the history
      // const assistantIndex = [...history].reverse().findIndex(msg => msg.role === 'assistant');
      // if (assistantIndex === -1) {
      //   throw new Error('No assistant message found in conversation history');
      // }
      
      // Create a copy of the history
      const updatedHistory = [...history];
      
      // Add a system message with the tool output
      updatedHistory.push({
        id: generateMessageId(),
        text: "Tool output:",
        sender: 'user',
        role: 'user',
        content: [
          {
            type: 'tool_result',
            tool_use_id: toolId,
            content: output
          }
        ],
        timestamp: new Date()
      });
      
      // Create a new AI message for streaming
      const aiMessageId = generateMessageId();
      
      // Add initial AI message
      set({
        messages: [
          ...updatedHistory,
          {
            id: aiMessageId,
            text: '',
            sender: 'ai',
            timestamp: new Date(),
            isStreaming: true
          },
        ],
        isStreaming: true,
        conversationHistory: updatedHistory,
        activeToolId: null,
        activeToolPayload: null
      });

      console.log('[spaceChatStore][submitToolOutput] Updated history:', updatedHistory);

      const normalizedHistory = updatedHistory.map((msg) => ({
        role: msg.sender === 'user' ? 'user' : msg.sender === 'ai' ? 'assistant' : msg.role,
        content: msg.content || (msg.sender === 'user' ? msg.text : msg.content)
      })).filter(msg => !!msg.content)
      
      // Send the tool output to the server with streaming enabled
      const response = await fetch(CLAUDE_ENDPOINTS.toolOutput(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          tool_id: toolId,
          output,
          conversation_history: normalizedHistory,
          stream: true,
          tools: getCommandsForClaude()
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Failed to submit tool output');
      }
      
      // Get the reader from the response body
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Failed to get response reader');
      }
      
      // Use the parseStream function to process the stream
      const { fullResponse, toolMessage } = await parseStream({
        reader,
        aiMessageId,
        logPrefix: 'submitToolOutput',
        onText: (text, fullResponse) => {
          set({ streamingText: fullResponse });
          
          // Update the AI message
          set((state) => {
            const updatedMessages = [...state.messages];
            const aiMessageIndex = updatedMessages.findIndex(msg => msg.id === aiMessageId);
            if (aiMessageIndex !== -1) {
              updatedMessages[aiMessageIndex] = {
                ...updatedMessages[aiMessageIndex],
                text: fullResponse
              };
            }
            return { messages: updatedMessages };
          });
        },
        onToolUse: (toolData) => {
          // Save the tool ID for later
          // set({ 
          //   activeToolId: toolData.id,
          //   isStreaming: false
          // });
          console.log('[spaceChatStore][onToolUse] Tool use:', toolData);
        },
        onDone: (fullResponse) => {
          // Add the assistant message to conversation history
          const assistantHistoryMessage = {
            role: 'assistant',
            content: fullResponse
          };
          
          set((state) => ({ 
            conversationHistory: [...updatedHistory, assistantHistoryMessage]
          }));
        },
        onError: (error) => {
          console.error('[spaceChatStore][submitToolOutput] Error in stream:', error);
        }
      });
      
      // Update the final AI message
      set((state) => {
        const updatedMessages = [...state.messages];
        const aiMessageIndex = updatedMessages.findIndex(msg => msg.id === aiMessageId);
        if (aiMessageIndex !== -1) {
          updatedMessages[aiMessageIndex] = {
            ...updatedMessages[aiMessageIndex],
            text: fullResponse,
            isStreaming: false
          };
        }

        if (toolMessage) {
          updatedMessages.push({
            id: generateMessageId(),
            text: "Requesting tool usage...",
            sender: 'ai',
            role: 'assistant',
            content: [{
              type: 'tool_use',
              ...toolMessage,
            }],
            timestamp: new Date()
          });
        }

        return { 
          messages: updatedMessages,
          isStreaming: false,
          activeToolPayload: toolMessage,
          activeToolId: toolMessage ? toolMessage.id : null
        };
      });
      
      return { response: fullResponse };
    } catch (error) {
      console.error('[spaceChatStore][submitToolOutput] Error submitting tool output:', error);
      
      // Add error message
      const errorMessage: ChatMessage = {
        id: generateMessageId(),
        text: `Error: ${error instanceof Error ? error.message : 'Unknown error'}`,
        sender: 'ai',
        timestamp: new Date(),
      };

      set((state) => ({
        messages: [...state.messages, errorMessage],
        activeToolId: null
      }));
      
      throw error;
    }
  },
  
  // Mention handling
  setMentionSearch: (search) => set({ mentionSearch: search }),
  setMentionSuggestions: (suggestions) => set({ mentionSuggestions: suggestions }),
  setMentions: (mentions) => set({ mentions }),
  setMentionStartIndex: (index) => set({ mentionStartIndex: index }),
  setMentionResults: (results) => set({ mentionResults: results }),
  setShowMentionResults: (show) => set({ showMentionResults: show }),
  
  handleMentionSelect: (entity) => {
    const { inputText, mentionStartIndex } = get();
    
    if (mentionStartIndex === -1) return;
    
    const beforeMention = inputText.substring(0, mentionStartIndex);
    const afterMention = inputText.substring(inputText.indexOf(' ', mentionStartIndex) > -1 
      ? inputText.indexOf(' ', mentionStartIndex) 
      : inputText.length);
    
    // Ensure displayName is a string
    const displayName = 'name' in entity && typeof entity.name === 'string' 
      ? entity.name 
      : String(entity.id);
    
    const newInputText = `${beforeMention}@${entity.id} ${afterMention}`;
    
    set({
      inputText: newInputText,
      mentions: [...get().mentions, { 
        id: entity.id, 
        displayName, 
        type: entity.type 
      }],
      mentionStartIndex: -1,
      showMentionResults: false
    });
  },
  
  // UI actions
  setMode: (mode) => set({ mode }),
  setModel: (model) => set({ model }),
  setIsHistoryOpen: (isOpen) => set({ isHistoryOpen: isOpen }),
  setActivePreview: (previewId) => set({ activePreview: previewId }),
  setIsContextVisible: (isVisible) => set({ isContextVisible: isVisible }),
  setIsActionsVisible: (isVisible) => set({ isActionsVisible: isVisible }),
  setNextAutoMessage: (message) => set({ nextAutoMessage: message }),
})); 