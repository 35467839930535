// External imports
import { debounce } from "lodash";
import './YProsemirrorEditor.scss';
// ProseMirror imports
import { baseKeymap } from "prosemirror-commands";
import { history, redo, undo } from "prosemirror-history";
import { keymap } from "prosemirror-keymap";
import { schema } from 'prosemirror-schema-basic';
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
// y-prosemirror imports
import { initProseMirrorDoc, yUndoPlugin } from "y-prosemirror";
import { yCursorPlugin, ySyncPlugin } from "y-prosemirror";
// y-websocket import
import * as Y from 'yjs';

import { useNotesAppStore } from "../store/notesAppStore";

interface EditorContainerProps {
  noteId: string;
  windowId: string;
  spaceId: string;
}

const EditorContainer: React.FC<EditorContainerProps> = ({ noteId, windowId, spaceId }) => {
  const { ydoc, provider, setEditorView } = useNotesAppStore(windowId, spaceId);
  const editorRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<EditorView | null>(null);
  const yXmlFragment = useRef<Y.XmlFragment>();
  const docRef = useRef<any>();
  const mappingRef = useRef<any>();

  useEffect(() => {
    if (!ydoc || !provider) return;

    yXmlFragment.current = ydoc.getXmlFragment('prosemirror');
    const { doc, mapping } = initProseMirrorDoc(yXmlFragment.current, schema);
    docRef.current = doc;
    mappingRef.current = mapping;
  }, [noteId, ydoc, provider]);

  useEffect(() => {
    if (!docRef.current ||
        !editorRef.current ||
        !yXmlFragment.current ||
        !mappingRef.current ||
        !provider
      ) return;

    const state = EditorState.create({
      doc: docRef.current,
      schema,
      plugins: [
        ySyncPlugin(yXmlFragment.current, { mapping: mappingRef.current }),
        yCursorPlugin(provider.awareness),
        yUndoPlugin(),
        history(),
        keymap({
          'Mod-z': undo,
          'Mod-y': redo,
          ...baseKeymap
        })
      ]
    });

    const view = new EditorView(editorRef.current, { state });
    setEditorView(view);
    viewRef.current = view;

    return () => {
      if (viewRef.current) {
        viewRef.current.destroy();
        viewRef.current = null;
        setEditorView(null);
      }
    };
  }, [noteId, provider, setEditorView]);
  
  return (
    <>
      <div className="prose-mirror-editor" ref={editorRef} />
    </>
  );
};

export default EditorContainer;
