import { AnyWindowEntity } from '../../../../types/windows';
import { getEntityDisplayName } from '../../utils/windowUtils';
import { 
  BrowserWindowContext, 
  ContentWindowContext, 
  DefaultWindowContext, 
  WindowContext} from '../types';

export const getWindowContext = (window: AnyWindowEntity): WindowContext | null => {
  if (!window) {
    console.log('getWindowContext received null/undefined window');
    return null;
  }

  console.log('Processing window in getWindowContext:', {
    window,
    type: window.type,
    entityId: window.type === 'window' ? window.entityId : null,
    entity: window.type === 'window' ? window.entity : null
  });

  if (window.type !== 'window') {
    console.log('Non-window entity in getWindowContext:', window.type);
    return null;
  }

  // Get content based on window type
  if (window.entity) {
    if (window.entityId === 'pdfium' || window.entityId === 'notes') {
      const context: ContentWindowContext = {
        type: window.entityId,
        name: window.entity.name,
        content: getEntityDisplayName(window.entity)
      };
      console.log('Created PDF/Notes context:', context);
      return context;
    } else if (window.entityId === 'browser') {
      const context: BrowserWindowContext = {
        type: 'browser',
        url: (window.entity as any)?.skeleton?.url || '',
        title: window.title || 'Untitled Browser'
      };
      console.log('Created browser context:', context);
      return context;
    }
  }

  // Default to just the window title and type
  const context: DefaultWindowContext = {
    type: window.entityId || 'unknown',
    title: window.title || 'Untitled'
  };
  // console.log('Created default context:', context);
  return context;
}; 