import { ObjectId } from 'bson';
import { create } from 'zustand';

import { spaceCRDTService } from '../services/crdt/spaceCRDTService';
import { spaceService } from '../services/spaceService';
import { AnyWindowEntity, WindowEntity } from '../types/windows';
import { notesService } from '../services/notesService';

export type SpaceStatus = 'idle' | 'loading' | 'loaded' | 'error';

export interface SpaceSettings {
  isContentsVisible: boolean;
  isChatVisible: boolean;
  bgColor?: string;
}

export interface Space {
  id: string;
  name: string;
  windows: Record<string, AnyWindowEntity>;
  settings: SpaceSettings;
  created?: Date;
  updated?: Date;
}

export interface WindowState {
  position: { x: number; y: number };
  size: { width: number; height: number };
}

export interface SpaceState {
  status: SpaceStatus;
  error?: Error;
  activeSpace: Space | null;
  windows: Record<string, AnyWindowEntity>;
  selectedIds: string[];
  initialized: boolean;
  activeWindowId: string | null;
  layout?: any;
  cleanupFn: () => void;
  initializing: boolean;
  currentSpaceId?: string;
  initializationStatus: 'idle' | 'initializing' | 'initialized' | 'error';
  windowStates: Record<string, WindowState>;
  windowZIndexes: Record<string, number>;
}

export interface SpaceStore extends SpaceState {
  getSpaceStatus: () => Promise<SpaceStatus>;
  // loadSpace: (spaceId: string) => Promise<void>;
  createSpaceId: () => string;
  initialize: (spaceId: string) => Promise<void>;
  addWindow: (window: WindowEntity) => void;
  removeWindow: (windowId: string) => void;
  updateWindow: (windowId: string, updates: Partial<AnyWindowEntity>) => void;
  selectWindow: (windowId: string, isMultiSelect: boolean) => void;
  groupSelected: () => void;
  ungroup: (windowId: string) => void;
  // createWindow: (params: Omit<WindowEntity, 'id'>) => string;
  setWindows: (windows: AnyWindowEntity[]) => void;
  setStatus: (status: SpaceStatus) => void;
  cleanup: () => void;
  updateSpaceName: (newName: string) => Promise<void>;
  setBackgroundColor: (bgColor: string) => void;
  // initializeSpace: (spaceId: string) => Promise<void>;
  showDebugger: boolean;
  toggleDebugger: () => void;
  setActiveWindow: (windowId: string | null) => void;
  bringWindowToFront: (windowId: string) => void;
  setWindowState: (windowId: string, state: WindowState) => void;
  updateWindowPosition: (windowId: string, position: { x: number; y: number }) => void;
  updateWindowSize: (windowId: string, size: { width: number; height: number }) => void;
}

export const useSpaceStore = create<SpaceStore>((set, get) => ({
  status: 'idle',
  activeSpace: null,
  windows: {},
  selectedIds: [],
  initialized: false,
  initializing: false,
  activeWindowId: null,
  layout: undefined,
  cleanupFn: () => {},
  currentSpaceId: undefined,
  initializationStatus: 'idle',
  showDebugger: false,
  windowStates: {},
  windowZIndexes: {},

  getSpaceStatus: async () => {
    return get()?.status;
  },

  createSpaceId: () => {
    console.log('[SpaceStore] Creating space id');
    const id = new ObjectId().toHexString();
    return id;
  },

  initialize: async (spaceId: string) => {
    const state = get();
    console.log('[SpaceStore] Starting initialization:', spaceId);

    if (state.initializationStatus === 'initializing') {
      console.log('[SpaceStore] Already initializing');
      return;
    }

    if (state.initializationStatus === 'initialized' && state.currentSpaceId === spaceId) {
      console.log('[SpaceStore] Already initialized for this space');
      return;
    }

    set({ initializationStatus: 'initializing' });
    try {
      const existingSpace = await spaceService.getSpace(spaceId);
      if (existingSpace) {
        delete existingSpace.created;
        delete existingSpace.updated;
      }
      console.log('[SpaceStore] Fetched space:', existingSpace);
      const newSpace = createUISpace(spaceId);

      const space = existingSpace || newSpace;

      spaceCRDTService.initializeSpace(spaceId, space);
    
      const cleanupFn = spaceCRDTService.observeSpace(spaceId, (update) => {
        console.log('[SpaceStore][update path] Received space update:', update);
        set((state) => ({
          ...state,
          activeSpace: update,
          windows: update.windows
        }));
      });

      set({ 
        initialized: true,
        activeSpace: space,
        windows: space?.windows || {},
        initializationStatus: 'initialized',
        currentSpaceId: spaceId,
        cleanupFn
      });
    } catch (err) {
      set({ 
        initializationStatus: 'error',
        initializing: false 
      });
      throw err;
    }
  },

  addWindow: (window: WindowEntity) => {
    const spaceId = get().activeSpace?.id;
    if (!spaceId) return;
    console.log('[SpaceStore][update path] Adding window:', window);
    const newWindowsState = {
      ...get().windows,
      [window.id]: {
        ...window,
        type: 'window'
      } as AnyWindowEntity
    }

    console.log('Adding window to store:', window);

    set((state) => ({
      windows: newWindowsState,
      activeWindowId: window.id
    } as Partial<SpaceStore>));
    
    // Sync full window state to CRDT
    // spaceCRDTService.addWindow(spaceId, (Object.keys(newWindowsState).length - 1).toString(), window);
    spaceCRDTService.addWindow(spaceId, window.id, window);
  },

  removeWindow: (windowId: string) => {
    const spaceId = get().activeSpace?.id;
    if (!spaceId) return;
    console.log('[SpaceStore][update path] Removing window:', windowId);

    set((state) => {
      const { [windowId]: removed, ...rest } = state.windows;
      return {
        windows: rest,
        selectedIds: state.selectedIds.filter((id) => id !== windowId),
      };
    });

    // Notify CRDT of window removal
    spaceCRDTService.removeWindow(spaceId, windowId);
  },

  updateWindow: (windowId: string, updates: Partial<AnyWindowEntity>) => {
    console.log('[SpaceStore] Updating window:', { windowId, updates });
    const spaceId = get().activeSpace?.id;
    if (!spaceId) return;

    // Update local state with proper type casting
    set((state) => ({
      windows: {
        ...state.windows,
        [windowId]: {
          ...state.windows[windowId],
          ...updates,
        },
        activeWindowId: windowId
      }
    } as Partial<SpaceStore>));

    // Sync full window state to CRDT
    spaceCRDTService.updateWindow(spaceId, windowId, updates);
  },

  selectWindow: (windowId: string, isMultiSelect: boolean) => {
    set((state) => ({
      selectedIds: isMultiSelect ? [...state.selectedIds, windowId] : windowId ? [windowId] : [],
    }));
  },

  groupSelected: () => {
    const { selectedIds } = get();
    console.debug('Store groupSelected called with:', { selectedIds });

    if (selectedIds.length < 2) {
      console.debug('Not enough windows selected to group');
      return;
    }

    const spaceId = get().activeSpace?.id;
    if (spaceId) {
      console.debug('Triggering CRDT groupWindows');
      spaceCRDTService.groupWindows(spaceId, selectedIds);
    }
  },

  ungroup: (windowId) => {
    const window = get().windows[windowId] as WindowEntity;
    if (!window?.tabs) return;

    // Remove tab properties from window
    const updatedWindow = {
      ...window,
      tabs: undefined,
      activeTabId: undefined,
      isParentWindow: undefined
    };

    set(state => ({
      windows: {
        ...state.windows,
        [windowId]: updatedWindow
      }
    }));

    spaceCRDTService.ungroupWindows(windowId);
  },

  setWindows: (windows: AnyWindowEntity[]) => {
    console.debug('Store setWindows called with:', {
      windowCount: windows.length,
      windows,
    });

    set((state) => {
      const newWindows = windows.reduce((acc, win) => {
        // All windows are now just WindowEntity
        if (!acc[win.id]) {
          acc[win.id] = win;
        }
        return acc;
      }, {} as Record<string, WindowEntity>);

      return { windows: newWindows };
    });
  },

  setStatus: (status) => {
    set({ status });
  },

  updateSpaceName: async (newName: string) => {
    console.log('[SpaceStore] Updating space name:', newName);
    const spaceId = get().activeSpace?.id;
    if (!spaceId) {
      console.error('Cannot update name - no active space');
      return;
    }

    try {
      await spaceService.updateSpaceName(spaceId, newName);
      
      // Update local state
      set((state) => ({
        activeSpace: state.activeSpace ? {
          ...state.activeSpace,
          name: newName
        } : null
      }));
    } catch (error) {
      console.error('Failed to update space name:', error);
      throw error;
    }
  },

  cleanup: () => {
    const { cleanupFn } = get();
    console.log('[SpaceStore] Cleaning up...');
    if (cleanupFn) {
      cleanupFn();
    }
    set({ 
      status: 'idle',
      activeSpace: null,
      windows: {},
      initialized: false,
      cleanupFn: undefined
    } as Partial<SpaceStore>);
  },

  setBackgroundColor: (bgColor: string) => {
    const spaceId = get().activeSpace?.id;
    if (!spaceId) return;

    const activeSpace = get().activeSpace;
    if (!activeSpace) return;

    const newSettings = {
      ...activeSpace.settings,
      bgColor
    };

    set((state) => ({
      activeSpace: {
        ...activeSpace,
        settings: newSettings
      }
    }));

    spaceCRDTService.updateSettings(spaceId, newSettings);
  },

  toggleDebugger: () => set(state => ({ showDebugger: !state.showDebugger })),

  setActiveWindow: (windowId: string | null) => {
    console.log('[SpaceStore] Setting active window:', windowId);
    set({ activeWindowId: windowId });
  },

  bringWindowToFront: (windowId: string) => {
    const { windows, windowZIndexes } = get();
    if (!windows[windowId]) {
      console.warn('[SpaceStore] Attempted to bring non-existent window to front:', windowId);
      return;
    }

    const maxZIndex = Math.max(0, ...Object.values(windowZIndexes));
    const newZIndexes = {
      ...windowZIndexes,
      [windowId]: maxZIndex + 1
    };

    // Update electron BrowserView if needed
    const electron = (window as any).electron;
    if (electron?.BrowserView?.reorder) {
      electron.BrowserView.reorder(newZIndexes);
    }

    set({ 
      activeWindowId: windowId,
      windowZIndexes: newZIndexes
    });
  },

  setWindowState: (windowId: string, state: WindowState) => {
    set(store => ({
      windowStates: {
        ...store.windowStates,
        [windowId]: state
      }
    }));
  },

  updateWindowPosition: (windowId: string, position: { x: number; y: number }) => {
    set(store => ({
      windowStates: {
        ...store.windowStates,
        [windowId]: {
          ...store.windowStates[windowId],
          position
        }
      }
    }));
  },

  updateWindowSize: (windowId: string, size: { width: number; height: number }) => {
    set(store => ({
      windowStates: {
        ...store.windowStates,
        [windowId]: {
          ...store.windowStates[windowId],
          size
        }
      }
    }));
  },
}));

export type { AnyWindowEntity, WindowEntity };
export default useSpaceStore;

const createUISpace = (id: string) => {
	return {
		id: id,
		entityType: 'Space',
		name: 'New Space',
		windows: {},
		settings: {
			isContentsVisible: false,
			isChatVisible: false,
		},
		created_at: new Date().toISOString(),
		updated_at: new Date().toISOString(),
	}
}
