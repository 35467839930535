import { flatten, unflatten } from 'flat';
import { v4 as uuidv4 } from 'uuid';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { WEBSOCKET_URL } from '../../api/config';

export interface CRDTUpdate<T> {
  type: string;
  data: T;
  timestamp: number;
}
// delivery - curselda bermedes
export class CRDTServiceWS {
  private static instance: CRDTServiceWS;
  private docs = new Map<string, Y.Doc>();
  private providers = new Map<string, WebsocketProvider>();
  private connections: Map<string, (update: any) => void> = new Map();
  private observers: Map<string, { observer: any; cleanup: () => void }> = new Map();
  private states: Map<string, Y.Map<any>> = new Map();
  private cleanupSource?: string;
  private ws?: WebSocket;
  private localOriginId = uuidv4();

  private constructor() {
    // Private constructor for singleton
  }

  public static getInstance(): CRDTServiceWS {
    if (!CRDTServiceWS.instance) {
      CRDTServiceWS.instance = new CRDTServiceWS();
    }
    return CRDTServiceWS.instance;
  }

  public ensureInitialized(key: string): Y.Doc {
    console.log('[CRDT-WS] Doc Ensuring initialized:', key);
    let doc = this.docs.get(key);
    if (!doc) {
      console.log('[CRDT-WS] Doc does not exist, creating new doc:', key);
      doc = new Y.Doc();
      this.docs.set(key, doc);

      let roomId = key;
      const provider = new WebsocketProvider(WEBSOCKET_URL, roomId, doc);
      this.providers.set(key, provider);
    } else {
      console.log('[CRDT-WS] Doc already exists:', key);
    }
    return doc;
  }

  connect<T>(key: string, onUpdate: (update: CRDTUpdate<T>) => void): () => void {
    console.log('[CRDT-WS] Connecting to:', key);
    const doc = this.ensureInitialized(key);

    const ymap = doc.getMap<T>(key);
    this.states.set(key, ymap);

    const debouncedObserver = (event: Y.YMapEvent<T>, transaction: Y.Transaction) => {
      console.log('[CRDT-WS][update path] debouncedObserver update:', transaction);
      if (transaction.origin === this.localOriginId) {
        console.log('[CRDT-WS] Ignoring local transaction:', transaction);
        return;
      }
      console.log('[CRDT-WS][update path] ymap:', ymap.toJSON());
      const data = this.ymapToObject<T>(ymap);
      if (data) {
        console.log('[CRDT-WS][update path] onUpdate:', data);
        onUpdate({
          type: 'update',
          data: data,
          timestamp: Date.now(),
        });
      }
    };

    ymap.observe(debouncedObserver);
    console.log('[CRDT-WS] Observer added for:', key);

    this.connections.set(key, onUpdate);
    this.observers.set(key, {
      observer: debouncedObserver,
      cleanup: () => {
        ymap.unobserve(debouncedObserver);
        console.log('[CRDT-WS] Observer removed for:', key);
        this.connections.delete(key);
        this.observers.delete(key);
      },
    });

    return () => {
      console.log('[CRDT-WS] Disconnecting from:', key);
      const observer = this.observers.get(key);
      if (observer) {
        observer.cleanup();
      }
    };
  }

  update<T extends object>(
    key: string,
    newData: T,
    options: {
      preserveOtherConnections?: boolean;
      preserveNoteConnections?: boolean;
      updateType?: 'update' | 'remove';
    } = {},
  ) {
    console.log('[CRDT-WS][update path] Updating:', { key, newData });

    const doc = this.ensureInitialized(key);
    const ymap = doc.getMap(key);
    const updateMap = this.objectToYmap<T>(newData);

    doc.transact(() => {
      if (options.updateType === 'remove') {
        this.removeMapEntries(ymap, updateMap);
      } else if (options.updateType === 'update') {
        this.updateMap(ymap, updateMap);
      }
    });

    this.states.set(key, ymap);

    if (!options.preserveOtherConnections) {
      this.cleanupObservers(key, options);
    }
  }

  disconnect(key: string) {
    console.log('[CRDT-WS] Disconnecting from:', key);
    const provider = this.providers.get(key);
    if (provider) {
      provider.disconnect();
      console.log('[CRDT-WS] Provider disconnected for:', key);
      this.providers.delete(key);
    }
    this.docs.delete(key);
    console.log('[CRDT-WS] Doc deleted for:', key);
  }

  cleanup() {
    for (const [key] of this.providers) {
      this.disconnect(key);
    }
  }

  // DEBUG HELPERS

  getProvider(key: string): WebsocketProvider | undefined {
    return this.providers.get(key);
  }

  getActiveDocs(): Map<string, Y.Doc> {
    return this.docs;
  }

  getActiveProviders(): Map<string, WebsocketProvider> {
    return this.providers;
  }

  getWebSocket(): WebSocket | null {
    return this.ws || null;
  }

  get(key: string) {
    return this.docs.get(key);
  }

  private updateMap<T>(ymap: Y.Map<any>, updateMap: Map<string, any>) {
    console.log('[CRDT-WS] map: Updating:', updateMap);
    for (const [k, v] of updateMap.entries()) {
      try {
        if (ymap.get(k) !== v) {
          console.log('[CRDT-WS] map: Setting:', k, v);
          ymap.set(k, v);
        }
      } catch (keyError) {
        console.error('[CRDT-WS] map: Error setting key:', k, 'Value:', v, 'Error:', keyError);
        // Continue with other keys even if one fails
      }
    }
  }

  private removeMapEntries<T>(ymap: Y.Map<any>, updateMap: Map<string, any>) {
    console.log('[CRDT-WS] map: Removing:', updateMap, updateMap.entries());
    for (const [k, v] of updateMap.entries()) {
      console.log('[CRDT-WS] map: Looking for:', k);
      ymap.forEach((value, mapKey) => {
        if (mapKey === k || mapKey.startsWith(k)) {
          console.log('[CRDT-WS] map: Removing:', mapKey);
          ymap.delete(mapKey);
        }
      });
    }
  }

  private cleanupObservers(key: string, options: { preserveNoteConnections?: boolean }) {
    console.log('[CRDT-WS] Cleaning up observers for:', key);
    for (const [connKey, observer] of this.observers.entries()) {
      if (connKey === key || (!options.preserveNoteConnections && !connKey.startsWith('note-'))) {
        console.log('[CRDT-WS] Cleaning up observer for:', connKey);
        observer.cleanup();
      }
    }
  }

  //
  // CRDT YMAP HELPERS
  //
  private ymapToObject<T>(ymap: Y.Map<T>) {
    const ymapObject = ymap.toJSON();

    const normalizedObject = this.removeParentEntries(ymapObject);
    const object = unflatten(normalizedObject) as T;
    return object;
  }

  private objectToYmap<T>(object: T) {
    const flatObject = flatten(object);
    return new Map(Object.entries(flatObject as Record<string, any>));
  }

  private removeParentEntries(object: any) {
    const keys = Object.keys(object);
    const parentKeys = keys.filter((key) => {
      return keys.some((k) => k.startsWith(key + '.'));
    });
    parentKeys.forEach((key) => {
      delete object[key];
    });
    return object;
  }
}

// Create a single instance to be used across the app
export const crdtServiceWS = CRDTServiceWS.getInstance();
