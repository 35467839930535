import './SpaceChat.scss';

import React, { useCallback, useEffect, useRef } from 'react';
import useSpaceStore from '../../../stores/spaceStore';
import { ChatHeader } from './components/chat/ChatHeader';
import { ChatInput } from './components/chat/ChatInput';
import { ChatMessages } from './components/chat/ChatMessages';
import { ContextSection } from './components/chat/ContextSection';
import { ThreadHistory } from './components/ThreadHistory';
import type { ChatMessage } from './types';
import { useContextRouterStore } from 'components/Space/SpaceChat/contextStore';
import { NotesEditorRef } from '../../../apps/NotesApp/components/NotesEditor';

import { useCommandRegistryStore } from '../../../stores/commandRegistryStore';
import { useSpaceChatStore } from './spaceChatStore';

interface SpaceChatProps {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
}

function composeContextSummary(message?: ChatMessage) {
  if (!message || !Array.isArray(message?.contentObject)) {
    return '';
  }

  const isResponseAction = message?.contentObject.some((action) => action.response);

  return `${(message?.contentObject).map((action) => action.ACTION_NAME).join(', ')} ${isResponseAction ? 'response' : ''}`;
}

const SpaceChat: React.FC<SpaceChatProps> = ({ isVisible, setIsVisible }) => {
  // Use the new store
  const {
    activeToolPayload,
    messages,
    setMessages,
    isProcessing,
    isStreaming,
    streamingText,
    inputText,
    setInputText,
    mode,
    setMode,
    model,
    setModel,
    isHistoryOpen,
    setIsHistoryOpen,
    activePreview,
    setActivePreview,
    isContextVisible,
    setIsContextVisible,
    isActionsVisible,
    setIsActionsVisible,
    mentionSearch,
    setMentionSearch,
    mentionSuggestions,
    setMentionSuggestions,
    mentions,
    setMentions,
    mentionStartIndex,
    setMentionStartIndex,
    mentionResults,
    setMentionResults,
    showMentionResults,
    setShowMentionResults,
    handleMentionSelect,
    sendMessage,
    sendMessageHistory,
    submitToolOutput,
    nextAutoMessage,
    setNextAutoMessage,
    threads,
    currentThreadIds,
    createThread,
    addMessageToThread,
    getCurrentThread,
    getThreadHistory,
    switchToThread,
    clearMessages,
  } = useSpaceChatStore();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const notesEditorRef = useRef<NotesEditorRef>(null);

  const { activeSpace } = useSpaceStore();
  const { windows, activeWindowId } = useSpaceStore();
  const { contextEntities, setContextEntities, currentSelection } = useContextRouterStore();
  const { executeCommand } = useCommandRegistryStore();
  // const [currentSelection, setCurrentSelection] = useState<SpaceSelection | null>(null);
  // const [isExecutingAction, setIsExecutingAction] = useState(false);
  // const [activeToolId, setActiveToolId] = useState<string | null>(null);

  // Handle DOM selection
  // const handleDOMSelection = () => {
  // Existing selection handling code
  // ...
  // };

  useEffect(() => {
    console.log('[SpaceChat][useEffect] windows:', windows);
  }, [windows]);

  useEffect(() => {
    console.log('[SpaceChat][useEffect] Active tool payload:', activeToolPayload);

    const handleToolUse = async (toolPayload: any) => {
      console.log('[SpaceChat][useEffect] Tool use:', toolPayload);
      const result = await executeCommand(toolPayload.name, {
        ...toolPayload.input,
        callback: (output: string) => {
          console.log('[SpaceChat][useEffect] Tool used:', toolPayload, output);
          submitToolOutput(toolPayload.id, output, messages);
        },
      });
    };

    if (activeToolPayload?.id) {
      handleToolUse(activeToolPayload);
    }
  }, [activeToolPayload?.id, executeCommand, submitToolOutput, messages]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Handle auto message
  useEffect(() => {
    if (nextAutoMessage) {
      setNextAutoMessage(null);
      sendMessageHistory(JSON.stringify(nextAutoMessage));
    }
  }, [nextAutoMessage, sendMessageHistory, setNextAutoMessage]);

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInputText(value);

    // Auto-adjust height
    e.target.style.height = 'auto';
    e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`;

    // Handle mention suggestions
    const lastAtIndex = value.lastIndexOf('@');
    if (lastAtIndex !== -1 && (lastAtIndex === 0 || value[lastAtIndex - 1] === ' ')) {
      const searchText = value.substring(lastAtIndex + 1).split(' ')[0];
      setMentionSearch(searchText);
      setMentionStartIndex(lastAtIndex);

      // Filter windows based on search text
      const filteredWindows = Object.values(windows).filter((window) =>
        (window as any)?.name?.toLowerCase().includes(searchText.toLowerCase()),
      );

      setMentionResults(filteredWindows);
      setShowMentionResults(true);
    } else {
      setShowMentionResults(false);
    }
  };

  // Handle send message
  const handleSendMessage = useCallback(async () => {
    if (!inputText.trim() || isProcessing) return;

    try {
      const selectedWindows = Object.entries(windows)
        .filter(([key]) => key !== 'activeWindowId')
        .map(([_, window]) => window);

      // Get context for the message
      const context: Record<any, any> = {
        spaceId: activeSpace?.id,
        selectedWindows,
        selection: currentSelection?.text,
        availableActions: Object.keys(executeCommand?.toString() ? executeCommand : {}),
      };

      if (activeWindowId) {
        const noteContent = await executeCommand('notes_getText', {
          windowId: activeWindowId,
          spaceId: activeSpace?.id,
        });
        context.activeWindow = {
          ...windows[activeWindowId],
          content: noteContent,
        };
      }

      console.log('[SpaceChat][handleSendMessage] Context:', context);

      // Send the message using the store function
      await sendMessage(inputText, context);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [
    inputText,
    isProcessing,
    activeWindowId,
    windows,
    contextEntities,
    currentSelection,
    executeCommand,
    activeSpace?.id,
    sendMessage,
  ]);

  // Handle send confirm
  const sendConfirm = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setInputText('');
      await sendMessageHistory('Confirm');
    },
    [sendMessageHistory, setInputText],
  );

  // Render model selector
  const renderModelSelector = () => (
    <div className="model-selector">
      <select value={model} onChange={(e) => setModel(e.target.value)}>
        <option value="claude-3.5-sonnet">Claude 3.5 Sonnet</option>
        <option value="claude-3-opus">Claude 3 Opus</option>
        <option value="claude-3-sonnet">Claude 3 Sonnet</option>
        <option value="claude-3-haiku">Claude 3 Haiku</option>
      </select>
    </div>
  );

  const handleClear = () => {
    setInputText('');
    setMessages([]);
    setActivePreview(null);
    setIsHistoryOpen(false);
  };

  const getModeDescription = () => {
    switch (mode) {
      case 'create':
        return 'Create mode';
      case 'chat':
        return 'Chat mode';
      case 'editor':
        return 'Editor mode';
      case 'exp1':
        return 'Experimental mode 1';
      default:
        return 'Unknown mode';
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSendMessage();
    setInputText('');
  };

  return (
    <div className={`chat-wrapper`}>
      <button className="chat-button" onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? '💬' : '💬'}
      </button>

      {isVisible && (
        <div className="chat-panel">
          <ChatHeader
            mode={mode}
            setMode={setMode}
            onNewChat={handleClear}
            onClose={() => setIsVisible(false)}
            getModeDescription={getModeDescription}
            threads={threads}
          >
            <div className="header-buttons">
              <button type="button" className="chatHeaderButton" onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
                🕰️
              </button>
              <button type="button" className="chatHeaderButton" onClick={handleClear}>
                New
              </button>
              <button className="closeButton" onClick={() => setIsVisible(false)}>
                ×
              </button>
            </div>
          </ChatHeader>

          {isHistoryOpen && (
            <ThreadHistory
              threads={getThreadHistory(mode)}
              onSelectThread={switchToThread}
              onClose={() => setIsHistoryOpen(false)}
            />
          )}

          <ChatMessages
            messages={messages}
            isLoading={isProcessing}
            activePreview={activePreview}
            setActivePreview={setActivePreview}
            messagesEndRef={messagesEndRef}
          />

          {/* {isStreaming && (
            <div className="streaming-overlay">
              <div className="streaming-content">
                <div className="streaming-indicator">Streaming to note...</div>
                <div className="streaming-text">{streamingText}</div>
              </div>
            </div>
          )} */}

          <div className="chat-controls">
            <button
              className={`context-toggle ${isContextVisible ? 'active' : ''}`}
              onClick={() => setIsContextVisible(!isContextVisible)}
            >
              Context
            </button>
          </div>

          <ContextSection
            contextEntities={contextEntities}
            isVisible={isContextVisible}
            // onRemove={(id) => removeContextEntity(id)}
            onRemove={() => {}}
          />

          <ChatInput
            inputText={inputText}
            isProcessing={isProcessing}
            mode={mode}
            model={model as any}
            onModelChange={setModel}
            mentionSuggestions={mentionSuggestions}
            onInputChange={handleInputChange}
            onSubmit={handleSubmit}
            onMentionSelect={handleMentionSelect}
          />
        </div>
      )}
    </div>
  );

  // return (
  //   <div className={`chat-wrapper`}>
  //     <ChatHeader
  //       isVisible={isVisible}
  //       setIsVisible={setIsVisible}
  //       isHistoryOpen={isHistoryOpen}
  //       setIsHistoryOpen={setIsHistoryOpen}
  //     />

  //     <div className="space-chat-content">
  //       {isHistoryOpen ? (
  //         <ThreadHistory
  //           threads={getThreadHistory(mode)}
  //           onSelectThread={(threadId) => {
  //             switchToThread(threadId);
  //             const thread = threads[threadId];
  //             if (thread) {
  //               setMessages(thread.messages);
  //             }
  //             setIsHistoryOpen(false);
  //           }}
  //           onClose={() => setIsHistoryOpen(false)}
  //         />
  //       ) : (
  //         <>
  //           <div className="space-chat-messages-container">
  //             <ChatMessages
  //               messages={messages}
  //               activePreview={activePreview}
  //               setActivePreview={setActivePreview}
  //               messagesEndRef={messagesEndRef}
  //             />
  //           </div>

  //           <div className="space-chat-input-container">
  //             {isContextVisible && (
  //               <ContextSection
  //                 contextEntities={contextEntities}
  //                 setContextEntities={setContextEntities}
  //                 isVisible={isContextVisible}
  //                 setIsVisible={setIsContextVisible}
  //               />
  //             )}

  //             <div className="space-chat-input-wrapper" ref={inputWrapperRef}>
  //               {showMentionResults && mentionResults && mentionResults.length > 0 && (
  //                 <MentionSuggestions
  //                   suggestions={mentionResults}
  //                   onSelect={handleMentionSelect}
  //                 />
  //               )}

  //               <ChatInput
  //                 value={inputText}
  //                 onChange={handleInputChange}
  //                 onSend={handleSendMessage}
  //                 isProcessing={isProcessing}
  //                 isStreaming={isStreaming}
  //                 inputRef={inputRef}
  //                 model={model}
  //                 setModel={setModel}
  //                 renderModelSelector={renderModelSelector}
  //               />
  //             </div>
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default SpaceChat;
