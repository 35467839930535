import './Notes.scss';

import { useNotesAppStore } from '../store/notesAppStore';
import { useSpaceStore } from '../../../stores/spaceStore';
import React, { useCallback, useEffect, useState, forwardRef } from 'react';

import NotesEditor, { NotesEditorRef } from './NotesEditor';
import NotesOverview from './NotesOverview';

console.log('[NotesApp] Module loaded');

export interface NotesAppProps {
  noteId?: string;
  onClose?: () => void;
  windowId: string;
  spaceId: string;
}

const NotesApp = forwardRef<NotesEditorRef, NotesAppProps>(({ noteId, windowId, spaceId, onClose }, ref) => {
  const { 
    initialize, 
    createNoteId, 
    recentNotes, 
    fetchRecentNotes, 
    setNoteId, 
    noteId: activeNoteId,
    editorViewGetCurrentText,
    storeId,
  } = useNotesAppStore(windowId, spaceId);
  
  const { updateWindow } = useSpaceStore();

  const resetSelectedNoteId = useCallback(() => {
    setNoteId(null);
  }, [setNoteId]);

  const handleNoteIdChange = useCallback((id: string) => {
    initialize(id);
    setNoteId(id);
  }, [initialize, setNoteId]);

  useEffect(() => {
    if (!activeNoteId) {
      fetchRecentNotes();
    }
  }, [activeNoteId, fetchRecentNotes]);

  // Sync state back to window entity
  useEffect(() => {
    if (windowId && activeNoteId) {
      const currentContent = editorViewGetCurrentText();
      console.log('[NotesApp] Syncing state to window:', {
        windowId,
        activeNoteId,
        contentLength: currentContent.length,
        storeId: storeId
      });
      
      updateWindow(windowId, {
        applicationState: {
          notes: {
            activeNoteId,
            content: currentContent
          }
        }
      });
    }
  }, [windowId, activeNoteId, editorViewGetCurrentText, updateWindow]);

  return (
    <div className="notes-app">
      {activeNoteId ? (
        <NotesEditor
          ref={ref}
          noteId={activeNoteId}
          windowId={windowId}
          spaceId={spaceId}
          onBack={resetSelectedNoteId}
          onClose={onClose}
        />
      ) : ( 
        <NotesOverview
          show={!activeNoteId}
          recentNotes={recentNotes}
          onCreateNew={async () => {
            const noteId = createNoteId();
            handleNoteIdChange(noteId);
          }}
          onSelectNote={handleNoteIdChange}
        />
      )}
    </div>
  );
});

NotesApp.displayName = 'NotesApp';

export default NotesApp;