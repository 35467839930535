import React, { useCallback, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';

import fetchService from '../../services/fetchService';
import { useFileService } from '../../services/FileService';
import useEntityStore from '../../stores/entityStore';
import useSpaceStore from '../../stores/spaceStore';
import { BaseEntityType } from '../../types';
import { generateWindowId, getWindowSizeAndPosition } from './utils/windowUtils';
import { API_BASE_URL } from '../../api/config';

// Update the type to handle both drag events and input events
type FileDropEvent = (
  | (DragEvent & { dataTransfer: DataTransfer })
  | (Event & { target: HTMLInputElement & { files: FileList } })
) &
  DropEvent;

const readAllFilesFromDirectory = async (
  directory: FileSystemDirectoryEntry,
  childEntityIds: string[],
  processFile: (file: File, groupIds?: string[]) => Promise<any>,
): Promise<File[]> => {
  const reader = directory.createReader();
  const entries: FileSystemEntry[] = await new Promise((resolve, reject) => reader.readEntries(resolve, reject));

  const files: File[] = [];

  for (const entry of entries) {
    if (entry.isFile) {
      const file = await new Promise<File>((resolve, reject) => (entry as FileSystemFileEntry).file(resolve, reject));
      const result = await processFile(file);
      if (result && result._id) {
        childEntityIds.push(result._id);
      }
      files.push(file);
    } else if (entry.isDirectory) {
      const subDirFiles = await readAllFilesFromDirectory(
        entry as FileSystemDirectoryEntry,
        childEntityIds,
        processFile,
      );
      files.push(...subDirFiles);
    }
  }

  return files;
};

// Move uploadFileToBackend before customFileGetter
const uploadFileToBackend = async (file: File, fileService: any, groupIds: string[] = []) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('groupIds', JSON.stringify(groupIds));

  try {
    return await fileService.addFile(file);
  } catch (error) {
    console.error(`Error uploading ${file.name}:`, error);
    throw error;
  }
};

// Custom Event handler for react-dropzone to handle both files and directories
const customFileGetter = async (event: DropEvent): Promise<File[]> => {
  console.log('Drop event:', event);

  // Handle drag and drop case
  const dragEvent = event as DragEvent;
  if (dragEvent.dataTransfer?.items) {
    const items = dragEvent.dataTransfer.items;
    const files: File[] = [];

    for (const item of items) {
      if (item.kind === 'file') {
        if (item.webkitGetAsEntry) {
          const entry = item.webkitGetAsEntry();
          if (entry?.isFile) {
            files.push(await getFileFromEntry(entry as FileSystemFileEntry));
          } else if (entry?.isDirectory) {
            files.push(...(await getFilesFromDirectory(entry as FileSystemDirectoryEntry)));
          }
        } else {
          const file = item.getAsFile();
          if (file) files.push(file);
        }
      }
    }
    return files;
  }

  // Handle file input case
  const inputEvent = event as Event;
  if ('target' in inputEvent && inputEvent.target instanceof HTMLInputElement && inputEvent.target.files) {
    console.log('Handling files from input:', inputEvent.target.files);
    return Array.from(inputEvent.target.files);
  }

  return [];
};

// Add these helper functions at the top of the file
const getFileFromEntry = (entry: FileSystemFileEntry): Promise<File> => {
  return new Promise((resolve, reject) => {
    entry.file(resolve, reject);
  });
};

const getFilesFromDirectory = async (entry: FileSystemDirectoryEntry): Promise<File[]> => {
  const dirReader = entry.createReader();
  const entries = await new Promise<FileSystemEntry[]>((resolve, reject) => {
    dirReader.readEntries(resolve, reject);
  });

  const files: File[] = [];
  for (const entry of entries) {
    if (entry.isFile) {
      const file = await getFileFromEntry(entry as FileSystemFileEntry);
      files.push(file);
    } else if (entry.isDirectory) {
      const subFiles = await getFilesFromDirectory(entry as FileSystemDirectoryEntry);
      files.push(...subFiles);
    }
  }
  return files;
};

// Add helper to determine app type
const getAppIdForFile = (file: File) => {
  const ext = file.name.split('.').pop()?.toLowerCase();
  switch (ext) {
    case 'pdf':
      return 'pdfium';
    case 'txt':
    case 'md':
      return 'webdocument';
    default:
      return 'webdocument'; // fallback
  }
};

// Main Prototype DropZone Component
const PrototypeDropZone: React.FC = () => {
  const fileService = useFileService();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const entityStore = useEntityStore();
  const { addWindow } = useSpaceStore();

  const updateProgress = (fileName: string, percent: number) => {
    setProgress((prev) => ({ ...prev, [fileName]: percent }));
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);
      setProgress({});
      setErrors({});

      try {
        console.log('[DropZone] Processing files:', acceptedFiles);

        // Group files by directory
        const filesByDirectory = new Map<string, File[]>();
        acceptedFiles.forEach((file) => {
          const dirInfo = (file as any).__directoryInfo;
          const key = dirInfo?.path || 'root';
          const existing = filesByDirectory.get(key) || [];
          filesByDirectory.set(key, [...existing, file]);
        });

        const allUploadedEntities: BaseEntityType[] = [];

        // Process each directory
        for (const [dirPath, files] of filesByDirectory.entries()) {
          // Upload files with progress tracking
          const uploadPromises = files.map(async (file) => {
            try {
              updateProgress(file.name, 0);
              const entity = await fileService.addFile(file);
              updateProgress(file.name, 100);

              // Open the file in a new window after successful upload
              if (entity) {
                const { size, position } = getWindowSizeAndPosition();
                const fileName = (entity.skeleton as any)?.fileName || entity.name || file.name;
                addWindow({
                  id: generateWindowId(),
                  type: 'window',
                  position,
                  size,
                  entityId: getAppIdForFile(file), // Use helper to determine app
                  title: fileName,
                  entity: entity,
                });
              }

              return entity;
            } catch (error) {
              console.error(`Failed to upload ${file.name}:`, error);
              setErrors((prev) => ({
                ...prev,
                [file.name]: error instanceof Error ? error.message : 'Upload failed',
              }));
              return null;
            }
          });

          const results = await Promise.all(uploadPromises);
          const uploadedEntities = results.filter(Boolean) as BaseEntityType[];
          allUploadedEntities.push(...uploadedEntities);

          // Create group if this was a directory upload
          if (dirPath !== 'root' && uploadedEntities.length > 0) {
            try {
              const formData = new FormData();
              formData.append('folderName', dirPath.split('/').pop() || 'Untitled Folder');
              formData.append('childEntities', JSON.stringify(uploadedEntities.map((f) => f._id)));
              formData.append('entityType', 'Group');

              await fetchService(`${API_BASE_URL}/files/create-group`, {
                method: 'POST',
                body: formData,
              });
            } catch (error) {
              console.error('Failed to create group:', error);
              // Continue even if group creation fails
            }
          }
        }

        // Refresh entity store if any files were uploaded
        if (allUploadedEntities.length > 0) {
          const currentSpaceId = entityStore.currentSpaceId;
          if (currentSpaceId) {
            console.log('[DropZone] Refreshing entities after upload');
            await entityStore.fetchEntities();
          }
        }
      } catch (error) {
        console.error('[DropZone] Upload failed:', error);
      } finally {
        setUploading(false);
      }
    },
    [fileService, entityStore, addWindow],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    getFilesFromEvent: (event) => customFileGetter(event),
    multiple: true,
  });

  // Render upload progress
  const renderProgress = () => {
    if (!uploading) return null;

    return (
      <div className="upload-progress">
        {Object.entries(progress).map(([fileName, percent]) => (
          <div key={fileName} className="file-progress">
            <div className="file-name">{fileName}</div>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${percent}%` }} />
            </div>
            {errors[fileName] && <div className="error">{errors[fileName]}</div>}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div {...getRootProps()} className="dropzone" style={dropzoneStyle}>
      <input {...getInputProps()} />
      <div style={{ textAlign: 'center' }}>
        {isDragActive ? (
          <p>Drop the files or folders here...</p>
        ) : (
          <p>Drag & drop files or folders here, or click to browse</p>
        )}
      </div>
      {renderProgress()}
    </div>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: '2px dashed #cccccc',
  borderRadius: '10px',
  padding: '20px',
  width: '100px',
  height: '100px',
  position: 'absolute',
  top: '50px',
  left: '10px',
  backgroundColor: 'rgba(0,0,0,0.5)',
  cursor: 'pointer',
};

export default PrototypeDropZone;
