import './SettingsApp.scss';

import { useEffect, useState } from 'react';

import { appRegistry } from '../../services/appRegistryService';
import { IntegrationService } from '../../services/integration/IntegrationService';
import { registryService } from '../../services/registryService';
import { AppDefinition } from '../../types/apps';
import { Integration, IntegrationType } from '../../types/Integration';
import { ImageViewerAppDefinition } from '../ImageViewerApp/definition';
import { NotesAppDefinition } from '../NotesApp/definition';
import { PDFiumAppDefinition } from '../PDFiumApp/definition';
import { WebDocumentAppDefinition } from '../WebDocumentApp/definition';
import HealthSection from './HealthSection';
import { API_BASE_URL } from '../../api/config';
import fetchService from '../../services/fetchService';

const SYSTEM_APPS: AppDefinition[] = [
  PDFiumAppDefinition,
  NotesAppDefinition,
  WebDocumentAppDefinition,
  ImageViewerAppDefinition,
  // ... add other system apps
];

interface SettingsAppProps {
  title: string;
  onClose: () => void;
}

const AVAILABLE_INTEGRATIONS = [
  {
    type: 'Gmail' as IntegrationType,
    icon: '📧',
    name: 'Gmail',
    description: 'Connect your Gmail account',
  },
  {
    type: 'AppleNote' as IntegrationType,
    icon: '📝',
    name: 'Apple Notes',
    description: 'Sync your Apple Notes',
  },
  {
    type: 'Notion' as IntegrationType,
    icon: '📘',
    name: 'Notion',
    description: 'Import your Notion workspace',
  },
];

function truncateEmbedding(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => truncateEmbedding(item));
  }

  if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'embedding' && Array.isArray(value)) {
        newObj[key] = `[${value.slice(0, 3).join(', ')}...]`;
      } else {
        newObj[key] = truncateEmbedding(value);
      }
    }
    return newObj;
  }

  return obj;
}

const SettingsApp: React.FC<SettingsAppProps> = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState<'integrations' | 'account' | 'apps'>('integrations');
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [installedApps, setInstalledApps] = useState<AppDefinition[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isChecking, setIsChecking] = useState(false);
  const [healthStatus, setHealthStatus] = useState<{
    registry?: { status: string; message: string };
    apps?: { status: string; message: string };
  }>({});
  const [registry, setRegistry] = useState<any>(null);
  const [registryDebug, setRegistryDebug] = useState<{
    registry: any;
    actions: any[];
    installedApps: any[];
  } | null>(null);
  const [activeDebugTab, setActiveDebugTab] = useState<'registry' | 'actions' | 'apps'>('registry');
  const [registryError, setRegistryError] = useState<string | null>(null);

  useEffect(() => {
    loadData();
    const loadRegistry = async () => {
      setRegistry(registryService.getRegistryState());
    };
    loadRegistry();
  }, []);

  useEffect(() => {
    const loadRegistryDebug = async () => {
      try {
        const debugInfo = await registryService.getDebugInfo();
        setRegistryDebug(debugInfo);
        setRegistryError(null);
      } catch (error) {
        console.error('Failed to load registry debug info:', error);
        setRegistryError(error instanceof Error ? error.message : 'Unknown error');
      }
    };

    loadRegistryDebug();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      setError(null);
      const [integrationsData, appsData] = await Promise.all([
        IntegrationService.getIntegrations(),
        appRegistry.getInstalledApps(),
      ]);
      setIntegrations(integrationsData);
      setInstalledApps(appsData);
    } catch (err) {
      console.error('Failed to load data:', err);
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddIntegration = async (integrationType: IntegrationType) => {
    try {
      await IntegrationService.createIntegration({
        integrationEntities: [integrationType],
        name: AVAILABLE_INTEGRATIONS.find((i) => i.type === integrationType)?.name || integrationType,
        metadata: {},
      });
      await loadData();
    } catch (err) {
      setError('Failed to add integration');
      console.error(err);
    }
  };

  const handleRemoveIntegration = async (integrationId: string) => {
    try {
      await IntegrationService.removeIntegration(integrationId);
      await loadData();
    } catch (err) {
      setError('Failed to remove integration');
      console.error(err);
    }
  };

  const handleInstallApp = async (app: AppDefinition) => {
    try {
      await appRegistry.installApp(app);
      await loadData();
    } catch (err) {
      setError('Failed to install app');
      console.error(err);
    }
  };

  const handleUninstallApp = async (appId: string) => {
    try {
      await appRegistry.uninstallApp(appId);
      await loadData();
    } catch (err) {
      setError('Failed to uninstall app');
      console.error(err);
    }
  };

  const checkHealth = async () => {
    setIsChecking(true);
    try {
      // Check both registry and app health
      const [registryData, appsData] = await Promise.all([
        fetchService(`${API_BASE_URL}/health/registry`),
        fetchService(`${API_BASE_URL}/health/apps`),
      ]);

      setHealthStatus({
        registry: {
          status: registryData?.status,
          message: registryData?.message,
        },
        apps: {
          status: appsData?.status,
          message: appsData?.message,
        },
      });
    } catch (error) {
      console.error('Error checking health:', error);
      setHealthStatus({
        registry: {
          status: 'error',
          message: 'Failed to check registry health',
        },
        apps: {
          status: 'error',
          message: 'Failed to check app health',
        },
      });
    } finally {
      setIsChecking(false);
    }
  };

  const repairRegistry = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/health/repair`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      const data = await response.json();
      if (data.status === 'success') {
        // Re-check health after repair
        await checkHealth();
      }
    } catch (error) {
      console.error('Error repairing registry:', error);
    }
  };

  const renderCurrentIntegrations = () => {
    const activeIntegrations = integrations.filter(
      (integration) => integration.skeleton.integrationEntities.length > 0,
    );

    if (activeIntegrations.length === 0) {
      return <div className="no-integrations">No integrations configured yet</div>;
    }

    return (
      <div className="current-integrations">
        <h4>Current Integrations</h4>
        {activeIntegrations.map((integration) => (
          <div key={integration._id} className="integration-item current">
            <div className="integration-info">
              <span className="integration-icon">
                {AVAILABLE_INTEGRATIONS.find((ai) => integration.skeleton.integrationEntities.includes(ai.type))
                  ?.icon || '🔌'}
              </span>
              <div className="integration-details">
                <h4>{integration.skeleton.name}</h4>
                <div className="integration-meta">
                  <span className={`status ${integration.skeleton.isConnected ? 'connected' : 'disconnected'}`}>
                    {integration.skeleton.isConnected ? 'Connected' : 'Not Connected'}
                  </span>
                  <span className="date">Added {new Date(integration.created_at).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
            <button className="remove-button" onClick={() => handleRemoveIntegration(integration._id!)}>
              Remove
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderApps = () => {
    return (
      <div className="apps-container">
        <div className="current-apps">
          <h4>Installed Apps</h4>
          {installedApps.map((app) => (
            <div key={app.appId} className="app-item current">
              <div className="app-info">
                <span className="app-icon">📱</span>
                <div className="app-details">
                  <h4>{app.name}</h4>
                  <p>{app.description}</p>
                  <div className="app-meta">
                    <span className="version">v{app.version}</span>
                  </div>
                </div>
              </div>
              {!SYSTEM_APPS.some((sysApp) => sysApp.appId === app.appId) && (
                <button className="remove-button" onClick={() => handleUninstallApp(app.appId)}>
                  Uninstall
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="available-apps">
          <h4>Available Apps</h4>
          <div className="apps-list">
            {SYSTEM_APPS.map((app) => (
              <div key={app.appId} className="app-item">
                <div className="app-info">
                  <span className="app-icon">📱</span>
                  <div className="app-details">
                    <h4>{app.name}</h4>
                    <p>{app.description}</p>
                  </div>
                </div>
                <button
                  className="add-button"
                  onClick={() => handleInstallApp(app)}
                  disabled={installedApps.some((i) => i.appId === app.appId)}
                >
                  {installedApps.some((i) => i.appId === app.appId) ? 'Installed' : 'Install'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="settings-app">
      <div className="settings-app__header">
        <h3>Settings</h3>
      </div>

      <div className="settings-app__tabs">
        <button
          onClick={() => setActiveTab('integrations')}
          className={`tab ${activeTab === 'integrations' ? 'active' : ''}`}
        >
          Integrations
        </button>
        <button onClick={() => setActiveTab('apps')} className={`tab ${activeTab === 'apps' ? 'active' : ''}`}>
          Apps
        </button>
        <button onClick={() => setActiveTab('account')} className={`tab ${activeTab === 'account' ? 'active' : ''}`}>
          Account
        </button>
      </div>

      <div className="settings-app__content">
        {activeTab === 'integrations' && (
          <div className="integrations-container">
            {renderCurrentIntegrations()}

            <div className="available-integrations">
              <h4>Available Integrations</h4>
              <div className="integrations-list">
                {loading ? (
                  <div className="loading">Loading integrations...</div>
                ) : error ? (
                  <div className="error">
                    {error}
                    <button onClick={loadData} className="retry-button">
                      Retry
                    </button>
                  </div>
                ) : (
                  AVAILABLE_INTEGRATIONS.map((integration) => (
                    <div key={integration.type} className="integration-item">
                      <div className="integration-info">
                        <span className="integration-icon">{integration.icon}</span>
                        <div className="integration-details">
                          <h4>{integration.name}</h4>
                          <p>{integration.description}</p>
                        </div>
                      </div>
                      <button
                        className="add-button"
                        onClick={() => handleAddIntegration(integration.type)}
                        disabled={integrations.some((i) => i.skeleton.integrationEntities.includes(integration.type))}
                      >
                        {integrations.some((i) => i.skeleton.integrationEntities.includes(integration.type))
                          ? 'Added'
                          : 'Add'}
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === 'apps' && renderApps()}

        {activeTab === 'account' && (
          <div className="account-settings">
            <p>Account settings coming soon...</p>
          </div>
        )}

        <HealthSection registryDebug={registryDebug} registryError={registryError} />
      </div>
    </div>
  );
};

export default SettingsApp;
