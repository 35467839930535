// index.tsx
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import App from './App'; // Make sure this is correct
import reportWebVitals from './reportWebVitals';
import { unregisterActions } from './services/actionService';

unregisterActions();

// This component conditionally renders either the System or the AppRouter based on the URL path
// const AppEntry = () => {
//   const location = useLocation();
//
//   // If the URL starts with /app, render standalone apps; otherwise, render the full System GUI
//   if (location.pathname.startsWith('/app')) {
//     return <AppRouter />;
//   }
//   if (location.pathname.startsWith('/system2')) {
//     return <AppRouter />;
//   }
//
//   return <App />;
// };

// Component to handle token storage
const TokenHandler: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    const storeToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem('authToken', token);
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    storeToken();
  }, [getAccessTokenSilently]);

  return <>{children}</>;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// const portalRoot = document.createElement('div');

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        }}
        // onRedirectCallback={(...args: any[]) => {
        //   console.log('>>>> args: ', args);
        //   // navigate('/');
        //   // window.location.href = window.location.origin;
        // }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <TokenHandler>
          <App />
        </TokenHandler>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
