import React from 'react';

import { ChatMode, Thread } from '../../types';
import { ModeSelector } from '../ModeSelector';

interface ChatHeaderProps {
  mode: ChatMode;
  setMode: (mode: ChatMode) => void;
  onNewChat: () => void;
  onClose: () => void;
  getModeDescription?: (mode: ChatMode) => string;
  threads: Record<string, Thread>;
  children?: React.ReactNode;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({
  mode,
  setMode,
  onNewChat,
  onClose,
  getModeDescription,
  threads,
  children
}) => {
  return (
    <div className="chat-header">
      <div className="panelTitle">Space AI</div>
      <ModeSelector 
        currentMode={mode} 
        onChange={setMode} 
        getDescription={getModeDescription}
        threads={threads}
      />
      {children}
    </div>
  );
}; 