import './DebuggerPanel.scss';

import React, { useEffect, useState } from 'react';

import { appRegistry } from '../../services/appRegistryService';
import { crdtServiceWS } from '../../services/crdt/crdtServiceWS';
import { useAppRegistryStore } from '../../stores/appRegistryStore';
import useEntityStore from '../../stores/entityStore';
import type { SpaceStore } from '../../stores/spaceStore';
import useSpaceStore from '../../stores/spaceStore';

interface DebugInfo {
  docs: Array<{ 
    key: string, 
    clientId: number, 
    totalClients: number
  }>;
  providers: Array<{ 
    roomName: string, 
    connected: boolean, 
    remotePeers: number
  }>;
  spaceData?: any;  // Add space data
  states?: any[];
}

export const DebuggerPanel: React.FC = () => {
  const [debugInfo, setDebugInfo] = useState<DebugInfo>({
    docs: [],
    providers: [],
    states: []
  });

  const spaceId = useSpaceStore((state: SpaceStore) => state.activeSpace?.id);
  const apps = useAppRegistryStore(state => state.apps);
  const initializeApps = useAppRegistryStore(state => state.initializeApps);
  const entities = useEntityStore(state => state.entities);

  useEffect(() => {
    const initApps = async () => {
      console.debug('[DebuggerPanel] Starting app initialization...');
      
      // First initialize system apps
      await appRegistry.initializeSystemApps();
      console.debug('[DebuggerPanel] System apps initialized');
      
      // Then load all apps into store
      await initializeApps();
      console.debug('[DebuggerPanel] Apps loaded into store');
    };
    initApps();
  }, [initializeApps]);

  useEffect(() => {
    const updateInfo = async () => {
      // Get space data if we have a space ID
      let spaceData;
      if (spaceId) {
        const spaceDoc = await crdtServiceWS.get(`space:${spaceId}`);
        spaceData = spaceDoc;
      }

      const info: DebugInfo = {
        docs: Array.from(crdtServiceWS.getActiveDocs()).map(([key, doc]) => ({
          key,
          clientId: doc.clientID,
          totalClients: doc.store.clients.size
        })),
        providers: Array.from(crdtServiceWS.getActiveProviders()).map(([key, provider]) => ({
          roomName: provider.roomname,
          connected: provider.wsconnected,
          remotePeers: provider.awareness.getStates().size
        })),
        spaceData
      };
      setDebugInfo(info);
    };

    // Update every second
    const interval = setInterval(updateInfo, 1000);
    updateInfo();

    return () => clearInterval(interval);
  }, [spaceId]);

  return (
    <div className="yjs-debugger">
      <div className="debug-header">=== Debugger Panel =========</div>
      
      <div className="debug-section">
        <strong>Entities:</strong> {Object.keys(entities).length} total
      </div>

      <div className="debug-section">
        <strong>Installed Apps:</strong>
        <table className="debug-table">
          <thead>
            <tr>
              <th>App</th>
              <th>ID</th>
              <th>Flags</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(apps).map(([appId, app]) => (
              <tr key={appId}>
                <td>{app.skeleton.icon} {app.skeleton.name}</td>
                <td className="muted">{appId}</td>
                <td>
                  <span className={`flag ${app.skeleton.requiresProps ? 'active' : ''}`}>⚡</span>
                  <span className={`flag ${app.skeleton.requiresEntity ? 'active' : ''}`}>📄</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="debug-section">
        <strong>Active Yjs Docs:</strong>
        <table className="debug-table">
          <thead>
            <tr>
              <th>Key</th>
              <th>Client ID</th>
              <th className="right">Clients</th>
            </tr>
          </thead>
          <tbody>
            {debugInfo.docs.map(doc => (
              <tr key={doc.key}>
                <td>{doc.key}</td>
                <td className="muted">{doc.clientId}</td>
                <td className="right">{doc.totalClients}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="debug-section">
        <strong>Active Yjs Providers:</strong>
        <table className="debug-table">
          <thead>
            <tr>
              <th>Room</th>
              <th className="center">Status</th>
              <th className="right">Peers</th>
            </tr>
          </thead>
          <tbody>
            {debugInfo.providers.map(provider => (
              <tr key={provider.roomName}>
                <td>{provider.roomName}</td>
                <td className="center">{provider.connected ? '🟢' : '🔴'}</td>
                <td className={`right ${provider.remotePeers > 0 ? 'active-peers' : 'muted'}`}>
                  {provider.remotePeers}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {debugInfo.spaceData && (
        <div className="debug-section">
          <strong>Space Data:</strong>
          <pre className="space-data">
            {JSON.stringify(debugInfo.spaceData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}; 