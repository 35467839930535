import useSpaceStore from '../../stores/spaceStore';
import { SpaceSettings, UISpace } from '../../types/spaces';
import { AnyWindowEntity, WindowEntity } from '../../types/windows';
import { CRDTServiceWS,crdtServiceWS } from './crdtServiceWS';

export interface SpaceUpdate {
  windows?: AnyWindowEntity[];
  activeWindowId?: string;
  layout?: any;
  settings?: {
    isContentsVisible?: boolean;
    isChatVisible?: boolean;
    bgColor?: string;
  };
}

interface SpaceCRDTData {
  windows: AnyWindowEntity[];
  timestamp: number;
}

export class SpaceCRDTService {
  private settingsListeners: Map<string, ((settings: Partial<SpaceSettings>) => void)[]> = new Map();
  private spaceUpdateListeners: Map<string, ((update: SpaceUpdate) => void)[]> = new Map();
  private initializedSpaces = new Set<string>();

  constructor(private crdt: CRDTServiceWS) {}

  private getKey(spaceId: string) {
    return `space:${spaceId}`;
  }

  hasListener(spaceId: string): boolean {
    const listeners = this.spaceUpdateListeners.get(spaceId);
    return !!listeners && listeners.length > 0;
  }

  onSpaceUpdate(spaceId: string, callback: (update: SpaceUpdate) => void) {
    console.log('[SpaceCRDT] Setting up listener for:', spaceId);
    
    return this.crdt.connect(`space:${spaceId}`, (update: any) => {
      // Make sure we're getting and sending the full windows array
      if (update.data?.windows) {
        // console.log('[SpaceCRDT] Got windows update:', update.data.windows);
        callback({
          ...update.data,
          windows: update.data.windows
        });
      }
    });
  }

  observeSpace(spaceId: string, onUpdate: (update: UISpace) => void) {
    const key = this.getKey(spaceId);
    console.log('[SpaceCRDT] Observing space:', key);
    return this.crdt.connect<UISpace>(key, (update) => {
      console.log('[SpaceCRDT] Got update:', !!update.data, update);
      if (update.data) {
        onUpdate(update.data);
      }
    });
  }

  removeWindow(spaceId: string, windowId: string) {
    console.log('[SpaceCRDT][update path] Removing window:', { spaceId, windowId });

    const key = this.getKey(spaceId);
    this.crdt.update(key, {
      windows: {
        [windowId]: undefined
      }
    }, {
      preserveOtherConnections: true,
      preserveNoteConnections: true,
      updateType: 'remove'
    });
  }

  addWindow(spaceId: string, windowId: string, updates: Partial<AnyWindowEntity>) {
    console.log('[SpaceCRDT][update path] Adding window:', { spaceId, windowId, updates });
    const key = this.getKey(spaceId);
    this.crdt.update(key, {
      windows: {
        [windowId]: updates
      }
    }, {
      preserveOtherConnections: true,
      preserveNoteConnections: true,
      updateType: 'update'
    });
  }

  updateWindow(spaceId: string, windowId: string, updates: Partial<AnyWindowEntity>) {
    console.log('[SpaceCRDT][update path] Updating window:', { spaceId, windowId, updates });
    const key = this.getKey(spaceId);
    this.crdt.update(key, {
      windows: {
        [windowId]: updates
      }
    }, {
      updateType: 'update',
      preserveOtherConnections: true,
      preserveNoteConnections: true,
    });
  }

  updateSettings(spaceId: string, settings: Partial<SpaceSettings>) {
    const key = this.getKey(spaceId);
    this.crdt.update(key, {
      settings,
    }, {
      updateType: 'update',
      preserveOtherConnections: true,
      preserveNoteConnections: true,
    });
    const listeners = this.settingsListeners.get(spaceId) || [];
    listeners.forEach((listener) => listener(settings));
  }

  disconnectAll() {
    console.log('[SpaceCRDT] Disconnecting all');
    this.settingsListeners.clear();
    this.spaceUpdateListeners.clear();
    this.initializedSpaces.clear();
    
    try {
      this.crdt.disconnect('space');
    } catch (err) {
      console.warn('[SpaceCRDT] Error during disconnect:', err);
    }
  }

  groupWindows(spaceId: string, windowIds: string[]) {
    const existingWindows = useSpaceStore.getState().windows;
    const firstWindow = existingWindows[windowIds[0]];
    if (!firstWindow) return;

    // Convert first window to parent window with tabs
    const updatedWindow: WindowEntity = {
      ...firstWindow,
      tabs: windowIds,
      activeTabId: windowIds[0],
      isParentWindow: true
    };

    // Update the windows
    const updatedWindows = Object.values(existingWindows).map(win => 
      win.id === firstWindow.id ? updatedWindow : win
    );
    
    // this.updateWindows(spaceId, updatedWindows);
  }

  ungroupWindows(windowId: string) {
    const spaceId = windowId.split('-')[0];
    const currentWindows = useSpaceStore.getState().windows;
    const window = currentWindows[windowId] as WindowEntity;

    if (!window?.tabs) return;

    // Remove tab properties from window
    const updatedWindow = {
      ...window,
      tabs: undefined,
      activeTabId: undefined,
      isParentWindow: undefined
    };

    const updatedWindows = Object.values(currentWindows).map(win => 
      win.id === windowId ? updatedWindow : win
    );
    
    // this.updateWindows(spaceId, updatedWindows);
  }

  async initializeSpace(spaceId: string, initialState: UISpace) {
    console.log('[SpaceCRDT] Initialize space:', spaceId, initialState);
    this.initializedSpaces.add(spaceId);
    this.crdt.update(this.getKey(spaceId), initialState, {
      updateType: 'update',
      preserveOtherConnections: true,
      preserveNoteConnections: true,
    });
  }

  async updateSpaceName(spaceId: string, name: string) {
    console.log('[SpaceCRDT] Updating name:', { spaceId, name });
    const key = this.getKey(spaceId);
    await this.crdt.update(key, {
      data: {
        name
      }
    }, {
      updateType: 'update',
      preserveOtherConnections: true,
      preserveNoteConnections: true,
    });
  }
}

// Only export one instance
export const spaceCRDTService = new SpaceCRDTService(crdtServiceWS);
