import './App.scss';

import Home from 'components/Home/Home';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import LandingPage from './pages/LandingPage';
import SpaceWrapperPage from './pages/SpaceWrapperPage';
import NewSpacePage from './pages/NewSpacePage';
import SignupPage from './pages/SignupPage';

const App = () => (
  <Routes>
    <Route path="/new" element={<NewSpacePage />} />
    <Route path="/spaces/:spaceId" element={<SpaceWrapperPage />} />
    <Route path="/home" element={<Home />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/signup" element={<SignupPage />} />
    <Route path="/" element={<LandingPage />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default App;
