import './PDFiumApp.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import { dispatchCustomEvent, useCustomEventListener } from '../../services/actionService';
import fetchService from '../../services/fetchService';
import { useFileService } from '../../services/FileService';
import { selectionService } from '../../services/selectionService';
import { PDFRenderer } from './components/PDFRenderer';
import { RecentFiles } from './components/RecentFiles';
import { PDF_EVENTS } from './definition';
import { initPDFium } from './lib/pdfium-wasm';
import { PDFDocument } from './lib/pdfium-wasm/types';
import { TextSelection } from './types';
import { API_BASE_URL } from '../../api/config';

export interface PDFiumAppProps {
  title: string;
  onClose: () => void;
  entity: {
    _id: string;
    name: string;
    type: string;
    entityType: string;
  };
}

export const PDFiumApp: React.FC<PDFiumAppProps> = ({ entity }) => {
  const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null);
  const [scale, setScale] = useState(1.0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const fileService = useFileService();
  const initRef = useRef(false);
  const [selection, setSelection] = useState<TextSelection | null>(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const [recentFiles, setRecentFiles] = useState<Array<{ id: string; name: string }>>([]);
  const loadingRef = useRef(false);
  const entityRef = useRef<{_id: string} | null>(null);
  const hasLoadedRef = useRef(false);

  // Load recent files on mount
  useEffect(() => {
    const loadRecentFiles = async () => {
      try {
        // TODO: Implement actual recent files fetch
        const files = await fileService.getRecentFiles('pdf');
        setRecentFiles(files);
      } catch (error) {
        console.error('Failed to load recent files:', error);
      }
    };
    loadRecentFiles();
  }, [fileService]);

  // Debounced version of loadPDF to prevent multiple API calls during drag
  const debouncedLoadPDF = useCallback(
    debounce(async () => {
      if (loadingRef.current || !entityRef.current?._id || hasLoadedRef.current) return;
      console.debug('📑 Executing debounced PDF load...');
      loadPDF();
    }, 300),
    [fileService]
  );

  // Cleanup PDF document when component unmounts
  useEffect(() => {
    return () => {
      if (pdfDoc) {
        try {
          console.debug('📑 Cleaning up PDF document on unmount');
          // Create a local reference to avoid race conditions
          const docToDestroy = pdfDoc;
          // Clear the state first to prevent multiple destroy calls
          setPdfDoc(null);
          // Then destroy the document with a delay to avoid race conditions
          setTimeout(() => {
            try {
              if (docToDestroy) {
                docToDestroy.destroy();
                console.debug('📑 Document destroyed successfully');
              }
            } catch (error) {
              console.error('Error destroying PDF document:', error);
            }
          }, 50);
        } catch (error) {
          console.error('Error in PDF cleanup:', error);
        }
      }
    };
  }, [pdfDoc]);

  // Only try to load PDF if we have an entity and we haven't loaded this PDF yet
  useEffect(() => {
    if (!entity || !entity._id) return;
    
    // Check if this is the same entity we already loaded
    if (entityRef.current?._id === entity._id && hasLoadedRef.current) {
      console.debug('📑 PDF already loaded for this entity, skipping reload');
      return;
    }
    
    // Update entity reference for debounced loader
    entityRef.current = entity;

    // Clean up previous PDF document if it exists
    if (pdfDoc) {
      try {
        console.debug('📑 Cleaning up previous PDF document');
        // Create a local reference to avoid race conditions
        const docToDestroy = pdfDoc;
        // Clear the state first to prevent multiple destroy calls
        setPdfDoc(null);
        // Then destroy the document
        setTimeout(() => {
          try {
            docToDestroy.destroy();
          } catch (error) {
            console.error('Error destroying PDF document:', error);
          }
        }, 50);
        
        // Reset hasLoaded flag since we're cleaning up
        hasLoadedRef.current = false;
      } catch (error) {
        console.error('Error in PDF cleanup:', error);
      }
    }
    
    initRef.current = true;

    // Use debounced loader to prevent multiple loads during drag
    debouncedLoadPDF();
    
    // Cancel debounced load on cleanup
    return () => {
      debouncedLoadPDF.cancel();
    };
  }, [entity?._id, debouncedLoadPDF]); // Include debouncedLoadPDF in dependencies

  const loadPDF = useCallback(async () => {
    // Prevent concurrent loads
    if (loadingRef.current || hasLoadedRef.current) {
      console.debug('📑 PDF load already in progress or already loaded, skipping');
      return;
    }
    
    loadingRef.current = true;
    console.debug('📑 Starting PDF load process...');
    try {
      setLoading(true);
      setError(null);

      console.debug('📂 Loading file data for ID:', entityRef.current?._id);
      
      try {
        if (!entityRef.current?._id) {
          throw new Error('No entity ID provided');
        }
        
        const fileData = await fileService.readFile(entityRef.current._id);

        if (!fileData) {
          throw new Error('No data received from server');
        }
        
        console.debug('📊 File data loaded:', {
          type: fileData.constructor.name,
          length: fileData.length,
          isUint8Array: fileData instanceof Uint8Array,
          firstBytes: Array.from(fileData.slice(0, 4)),
        });

        console.debug('🔧 Initializing PDFium...');
        const pdfium = await initPDFium();
        if (!pdfium) {
          throw new Error('Failed to initialize PDFium');
        }
        console.debug('✅ PDFium initialized');

        console.debug('📖 Loading document into PDFium...');
        const doc = await pdfium.loadDocument(fileData);
        console.debug('✅ Document loaded:', doc);

        setPdfDoc(doc);
        // Mark as loaded to prevent reloading
        hasLoadedRef.current = true;
      } catch (error: any) {
        console.error('❌ Error loading PDF file data:', error);
        
        // Handle structured error responses from the backend
        if (error.response && typeof error.response.json === 'function') {
          try {
            const errorData = await error.response.json();
            
            if (errorData.error) {
              if (errorData.details) {
                throw new Error(`${errorData.error}: ${errorData.details}`);
              } else {
                throw new Error(errorData.error);
              }
            }
          } catch (jsonError) {
            // If we couldn't parse the JSON, fall back to the original error
            console.error('Failed to parse error response:', jsonError);
          }
        }
        
        // Check for common error scenarios
        if (error.message?.includes('404') || error.message?.includes('Not Found') || 
            error.message?.includes('File not found')) {
          throw new Error('The file could not be found on the server. It may have been moved or deleted.');
        } else if (error.message?.includes('401') || error.message?.includes('Unauthorized')) {
          throw new Error('You do not have permission to access this file.');
        } else if (error.message?.includes('Connection') || error.message?.includes('NameResolution')) {
          throw new Error('Cannot connect to the file storage server. Please check your network connection.');
        } else if (error.status === 503) {
          throw new Error('The file storage service is temporarily unavailable. Please try again later.');
        } else {
          throw error; // Re-throw for the outer catch
        }
      }
    } catch (error: any) {
      console.error('❌ Error loading PDF:', error);
      
      let errorMessage = 'Failed to load PDF';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else if (error && typeof error === 'object' && 'message' in error) {
        errorMessage = String(error.message);
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, [fileService]);

  // Listen for events
  useCustomEventListener(PDF_EVENTS.ZOOM_IN, () => handleScaleChange(scale * 1.1));
  useCustomEventListener(PDF_EVENTS.ZOOM_OUT, () => handleScaleChange(scale / 1.1));
  useCustomEventListener(PDF_EVENTS.RESET_ZOOM, () => handleScaleChange(1.0));

  const handleScaleChange = (newScale: number) => {
    setScale(newScale);
  };

  const handleMouseLeave = () => {
    setIsSelecting(false);
  };

  const handleFinalSelection = useCallback(
    (selection: TextSelection | null) => {
      if (!entity || !selection) return; // Early return if no entity

      console.log('🎯 handleFinalSelection called with:', selection);

      selectionService.setSelection({
        text: selection.text,
        source: {
          window: {
            id: entity._id,
            type: 'window' as const,
            entityId: 'pdfium',
            position: { x: 0, y: 0 },
            size: { width: 0, height: 0 },
          },
          type: 'pdf',
          metadata: {
            pageNumber: selection.startPage + 1,
          },
        },
      });

      console.log('📢 Dispatching SELECT_TEXT event');
      dispatchCustomEvent(PDF_EVENTS.SELECT_TEXT, {
        entityId: entity._id,
        text: selection.text,
        pageNumber: selection.startPage + 1,
      });
    },
    [entity],
  );

  // Modify handleSelectionChange to only update visual state
  const handleSelectionChange = (
    newSelection: TextSelection | null | ((prev: TextSelection | null) => TextSelection | null),
  ) => {
    const resolvedSelection = typeof newSelection === 'function' ? newSelection(selection) : newSelection;
    setSelection(resolvedSelection);
  };

  // Handle selection completion
  const handleSelectionComplete = useCallback(() => {
    if (!entity || !selection) return; // Early return if no entity

    console.log('🎯 Selection complete:', {
      hasSelection: !!selection,
      text: selection?.text,
      indices: {
        start: selection?.startIndex,
        end: selection?.endIndex,
      },
    });

    if (selection?.text) {
      const cleanText = selection.text.replace(/\x00/g, '').trim();
      if (cleanText) {
        const selectionData = {
          text: cleanText,
          source: {
            window: {
              id: entity._id,
              type: 'window' as const,
              entityId: 'pdfium',
              position: { x: 0, y: 0 },
              size: { width: 0, height: 0 },
            },
            type: 'pdf',
            metadata: {
              pageNumber: selection.startPage + 1,
              startIndex: selection.startIndex,
              endIndex: selection.endIndex,
            },
          },
        };

        console.log('📤 Sending to selectionService:', selectionData);
        selectionService.setSelection(selectionData);
      }
    }
  }, [entity, selection]);

  const handleEnrich = async () => {
    if (!entity) return; // Early return if no entity

    try {
      const result = await fetchService(`${API_BASE_URL}/enrich_pdf`, {
        method: 'POST',
        body: JSON.stringify({ fileId: entity._id }),
      });

      console.log('Enriched PDF:', result);
    } catch (error) {
      console.error('Error enriching PDF:', error);
    }
  };

  const handleInspect = async () => {
    if (!entity) return; // Early return if no entity

    try {
      const result = await fetchService(`${API_BASE_URL}/inspect_pdf?fileId=${entity._id}`);

      if (result.error) {
        throw new Error(result.error);
      }

      console.log('Inspection Data:', result);
    } catch (e) {
      console.error('Error inspecting PDF:', e);
      setError(e instanceof Error ? e.message : 'Failed to inspect PDF');
    }
  };

  const handleFileSelect = useCallback((fileId: string) => {
    // TODO: Implement file opening logic
    console.log('Opening file:', fileId);
  }, []);

  // If no entity, show recent files
  if (!entity) {
    return (
      <div className="pdf-app">
        <RecentFiles onFileSelect={handleFileSelect} files={recentFiles} />
      </div>
    );
  }

  // Rest of the existing component for when we have an entity
  if (loading) return <div className="loading-container">Loading PDF...</div>;
  
  if (error) {
    return (
      <div className="pdf-error-container">
        <div className="pdf-error-content">
          <h3>Unable to load PDF</h3>
          <p className="error-message">{error}</p>
          
          <div className="error-actions">
            <button 
              className="retry-button" 
              onClick={() => {
                setError(null);
                setLoading(true);
                // Trigger reload of the PDF
                setTimeout(() => {
                  if (entity && entity._id) {
                    console.log('🔄 Retrying PDF load...');
                    loadPDF();
                  }
                }, 500);
              }}
            >
              Try Again
            </button>
            
            <button 
              className="back-button"
              onClick={() => {
                // Go back to recent files view
                const id = entity?._id;
                dispatchCustomEvent('CLOSE_WINDOW', { entityId: id });
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  if (!pdfDoc) return <div className="loading-container">No document loaded</div>;

  return (
    <div className="pdf-app" onMouseLeave={handleMouseLeave}>
      <div className="pdf-container">
        <div className="pdf-viewer">
          <div className="pdfium-toolbar">
            <button onClick={() => dispatchCustomEvent(PDF_EVENTS.ZOOM_IN, { entityId: entity._id })}>Zoom In</button>
            <button onClick={() => dispatchCustomEvent(PDF_EVENTS.ZOOM_OUT, { entityId: entity._id })}>Zoom Out</button>
            <button onClick={() => dispatchCustomEvent(PDF_EVENTS.RESET_ZOOM, { entityId: entity._id })}>
              Reset Zoom
            </button>
            <button onClick={handleEnrich}>Enrich</button>
            <button onClick={handleInspect}>Inspect</button>
            <span>{Math.round(scale * 100)}%</span>
          </div>
          <PDFRenderer
            document={pdfDoc}
            scale={scale}
            selection={selection}
            setSelection={handleSelectionChange}
            isSelecting={isSelecting}
            setIsSelecting={(selecting, finalSelection) => {
              setIsSelecting(selecting);
              if (!selecting && finalSelection?.text) {
                handleFinalSelection(finalSelection);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
