import './Window.scss';

import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';

import type { AnyWindowEntity } from '../../types/windows';
import TabGroup from './TabGroup';

interface WindowProps {
  children?: React.ReactNode;
  title: string;
  position: { x: number; y: number };
  size: { width: number; height: number };
  isSelected?: boolean;
  id: string;
  zIndex: number;
  onClose: () => void;
  onClick: (windowId: string, event: React.MouseEvent) => void;
  onFocus?: () => void;
  onDrag?: (windowId: string, x: number, y: number) => void;
  onDragStop: (x: number, y: number) => void;
  onResizeStop: (width: number, height: number) => void;
  isFocused?: boolean;
  onAddWindow?: () => void;
  
  // Tab props
  tabs?: string[];
  activeTabId?: string;
  onActivateTab?: (tabId: string) => void;
  onCloseTab?: (tabId: string) => void;

  // New props for tab content
  entityId?: string;
  tabContents: Record<string, React.ReactNode>;
}

const Window: React.FC<WindowProps> = ({
  children,
  title,
  position,
  size,
  isSelected,
  id,
  zIndex,
  onClose,
  onClick,
  onFocus,
  onDrag,
  onDragStop,
  onResizeStop,
  isFocused,
  onAddWindow,
  tabs,
  activeTabId,
  onActivateTab,
  onCloseTab,
  entityId,
  tabContents,
}) => {
  const [showFocusStyle, setShowFocusStyle] = useState(false);

  useEffect(() => {
    if (isFocused) {
      setShowFocusStyle(true);
      const timer = setTimeout(() => {
        setShowFocusStyle(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isFocused]);

  // Add debug properties to window object
  (window as any).__DEBUG_WINDOW_ID__ = id;
  (window as any).__DEBUG_PARENT_ID__ = tabs?.[0] || null;

  const handleCloseTab = (tabId: string) => {
    if (tabs?.length === 2) {
      // If only 2 tabs left, closing one should remove tab properties
      onClose();
    } else if (tabs) {
      // Remove this tab from the tabs array
      const newTabs = tabs.filter(id => id !== tabId);
      onActivateTab?.(newTabs[0]);  // Activate first remaining tab
      // Parent component should handle updating the window
    }
  };

  const handleClose = () => {
    if (tabs) {
      // Close all tab windows when closing a tabbed window
      tabs.forEach(tabId => onCloseTab?.(tabId));
    }
    onClose();
  };

  return (
    <Rnd
      style={{ zIndex: zIndex * 2 }}
      minWidth={200}
      minHeight={150}
      onMouseDown={onFocus}
      position={position}
      size={size}
      dragHandleClassName="window-header"
      onDrag={(e, d) => {
        // console.log('[Window] onDrag:', d);
        if (onDrag) {
          // console.log('[Window] Calling onDrag prop'); 
          onDrag(id, d.x, d.y);
        }
      }}
      onDragStop={(e, d) => onDragStop(d.x, d.y)}
      onResizeStop={(e, direction, ref, delta, position) => {
        onResizeStop(ref.offsetWidth, ref.offsetHeight);
        onDragStop(position.x, position.y);
      }}
      enableResizing={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
    >
      <div
        className={`window ${isSelected ? 'selected' : ''} ${showFocusStyle ? 'focused' : ''}`}
        data-window-id={id}
        onClick={(e) => e.stopPropagation()}
        style={{ '--window-z-index': zIndex * 2 } as React.CSSProperties}
      >
        <div className="window-header">
          <div
            className="select-handle"
            onMouseDown={(e) => {
              e.stopPropagation();
              onClick(id, e);
            }}
          />
          <span className="window-title">{title}</span>
          
          {tabs && (
            <TabGroup
              windowIds={tabs}
              activeId={activeTabId || tabs[0]}
              onActivate={onActivateTab}
              onCloseTab={handleCloseTab}
            />
          )}

          <div className="window-header-buttons">
            <button className="systemButton systemAddButton" onClick={onAddWindow}>
              +
            </button>
            <button className="systemButton systemCloseButton" onClick={handleClose}>
              ×
            </button>
          </div>
        </div>

        <div className="window-content" onClick={(e) => e.stopPropagation()}>
          {tabs ? (
            <div className="tabbed-window-content">
              {tabs.map((tabId) => (
                <div
                  key={tabId}
                  style={{
                    display: tabId === activeTabId ? 'block' : 'none',
                    height: '100%',
                  }}
                >
                  {tabContents[tabId === id ? `${id}-${entityId}` : tabId]}
                </div>
              ))}
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </Rnd>
  );
};

export default Window;
