import './ThreadHistory.scss';

import React, { useEffect, useRef } from 'react';

import { ThreadSummary } from '../types';

interface ThreadHistoryProps {
  threads: ThreadSummary[];
  onSelectThread: (threadId: string) => void;
  onClose: () => void;
}

export const ThreadHistory: React.FC<ThreadHistoryProps> = ({
  threads,
  onSelectThread,
  onClose
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const getTimeLabel = (date: Date): string => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    if (days === 0) return 'Today';
    if (days === 1) return 'Yesterday';
    if (days < 7) return `${days}d ago`;
    return new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  };

  // Sort threads by lastActive
  const sortedThreads = [...threads].sort(
    (a, b) => b.lastActive.getTime() - a.lastActive.getTime()
  );

  return (
    <div className="thread-history" ref={containerRef}>
      <div className="thread-history-header">
        <div className="panelTitle">Chat History</div>
        <button 
          className="close-button" 
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          ×
        </button>
      </div>
      <div className="thread-list">
        {sortedThreads.map(thread => (
          <button
            key={thread.id}
            className="thread-item"
            onClick={(e) => {
              e.stopPropagation();
              onSelectThread(thread.id);
            }}
          >
            <div className="thread-info">
              <div className="thread-header">
                <span className="thread-title">{thread.title}</span>
              </div>
              <div className="thread-meta-line">
                <span className="thread-time">{getTimeLabel(thread.lastActive)} •</span>
                <span className="thread-meta">
                  {thread.messageCount} messages
                </span>
              </div>
            </div>
            <span className="thread-type">{thread.mode}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

function formatRelativeTime(date: Date): string {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  
  if (diff < 60000) return 'just now';
  if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
  if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
  return `${Math.floor(diff / 86400000)}d ago`;
} 