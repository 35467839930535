import './SystemMenuButton.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useSpaceStore } from '../../../stores/spaceStore';
import BuyGalacticaModal from './BuyGalacticaModal';
import { SystemLocation, SystemLocationSelector } from './SystemLocationSelector';

const SystemMenuButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const createSpaceId = useSpaceStore((state) => state.createSpaceId);
  const [currentLocation, setCurrentLocation] = useState<SystemLocation>('Macbook Pro');
  const toggleDebugger = useSpaceStore((state) => state.toggleDebugger);
  const showDebugger = useSpaceStore((state) => state.showDebugger);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('authToken');
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  const handleCreateSpace = useCallback(() => {
    const newSpaceId = createSpaceId();
    setIsOpen(false);
    navigate(`/spaces/${newSpaceId}`);
  }, [navigate, createSpaceId]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={menuRef} className="menu-button-container">
      <button className={`menu-button ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        ⚫️
      </button>
      {isOpen && (
        <div className="menu-dropdown">
          <SystemLocationSelector currentLocation={currentLocation} onLocationChange={setCurrentLocation} />
          <div className="menu-separator" />
          <div className="menu-item" onClick={handleCreateSpace}>
            New Space
          </div>
          <div
            className="menu-item"
            onClick={() => {
              setIsBuyModalOpen(true);
              setIsOpen(false);
            }}
          >
            Purchase Galactica I
          </div>
          <div className="menu-separator" />
          <div className="menu-item" onClick={() => navigate('/home')}>
            Go Home
          </div>
          <div
            className="menu-item"
            onClick={() => {
              toggleDebugger();
              setIsOpen(false);
            }}
          >
            {showDebugger ? 'Hide' : 'Show'} Debug Panel
          </div>
          <div className="menu-separator" />
          <div className="menu-item" onClick={handleLogout}>
            Log Out
          </div>
        </div>
      )}
      <BuyGalacticaModal isOpen={isBuyModalOpen} onClose={() => setIsBuyModalOpen(false)} />
    </div>
  );
};

export default SystemMenuButton;
