import './ContextSection.scss';

import React from 'react';

import { SpaceSelection } from '../../../../../types/selection';
import { AnyWindowEntity } from '../../../../../types/windows';
import { getWindowContext } from '../../utils/windowContext';
import { ContextItem } from '../ContextItem';
import { useContextRouterStore } from '../../contextStore';

interface ContextSectionProps {
  contextEntities: AnyWindowEntity[];
  isVisible: boolean;
  onRemove: (id: string) => void;
}

export const ContextSection: React.FC<ContextSectionProps> = ({
  contextEntities,
  isVisible,
  onRemove
}) => {
  const { currentSelection, setSelection } = useContextRouterStore();
  
  if (!isVisible) {
    return null;
  }
  
  return (
    <div className="context-section">
      <div className="context-header">
        <span>Context</span>
        <button className="context-add">+</button>
      </div>
      <div className="context-items">
        {contextEntities.map((entity) => (
          <ContextItem
            key={entity.id}
            entity={entity}
            onRemove={() => onRemove(entity.id)}
          />
        ))}
        {currentSelection && (
          <div className="context-item selection">
            <span className="context-item-title">
              Selection ({getWindowContext(currentSelection.source.window)?.type || 'Unknown'})
            </span>
            <div className="selection-text">
              {currentSelection.text.slice(0, 50)}
              {currentSelection.text.length > 50 ? '...' : ''}
            </div>
            <button className="context-item-remove" onClick={() => setSelection(null)}>
              ×
            </button>
          </div>
        )}
      </div>
    </div>
  );
}; 