import { API_BASE_URL } from '../api/config';
import { FILES_ENDPOINTS, PDF_ENDPOINTS } from '../endpoints';
import { BaseEntityType } from '../types';
import { entityCRDTService } from './crdt/entityCRDTService';
import fetchService from './fetchService';
import { v4 as uuidv4 } from 'uuid';

export interface EnrichmentStatus {
  status: 'not_started' | 'processing' | 'completed' | 'failed';
  error?: string | null;
}

export interface PdfEnrichmentResult {
  raw_text: string;
  sections: Record<string, string>;
  chunks: any[];
  metadata: any;
  summaries: Record<string, string>;
}

export class FileService {
  async getList(parentEntityId?: string): Promise<BaseEntityType[]> {
    try {
      const endpoint = parentEntityId === 'default' ? FILES_ENDPOINTS.list() : FILES_ENDPOINTS.list(parentEntityId);

      const response = await fetchService(endpoint);

      // Filter out invalid entities before mapping
      return response
        .filter((entity: any) => {
          if (!entity || !entity._id) {
            console.warn('[FileService] Skipping invalid entity:', entity);
            return false;
          }
          return true;
        })
        .map((entity: any) => {
          // Determine entity type from various possible sources
          const entityType = entity.entityType || entity.type || 'File';

          // Preserve windows for Space entities
          if (entityType === 'Space') {
            return {
              _id: entity._id,
              id: entity._id,
              name: entity.name || 'Untitled!',
              type: entityType.toLowerCase(),
              entityType: entityType,
              created_at: entity.created_at || new Date().toISOString(),
              updated_at: entity.updated_at || new Date().toISOString(),
              skeleton: {
                '@type': entityType,
                ...entity.skeleton, // Keep existing skeleton including windows
              },
            };
          }

          // Regular mapping for other entities...
          return {
            _id: entity._id,
            id: entity._id,
            name: entity.name || 'Untitled',
            type: entityType.toLowerCase(),
            entityType: entityType,
            created_at: entity.created_at || new Date().toISOString(),
            updated_at: entity.updated_at || new Date().toISOString(),
            skeleton: {
              '@type': entityType,
              ...(entity.skeleton || {}),
              // Ensure minimal skeleton properties based on type
              ...(entityType === 'File'
                ? {
                    fileId: entity._id,
                    fileName: entity.name || 'Untitled',
                    groupIds: [],
                    mimeType: entity.mimeType || 'application/octet-stream',
                    size: entity.size || 0,
                  }
                : {}),
              ...(entityType === 'Group'
                ? {
                    childEntities: entity.skeleton?.childEntities || [],
                  }
                : {}),
            },
          };
        });
    } catch (error) {
      console.error('[FileService] Failed to get list:', error);
      throw error;
    }
  }

  async addFile(file: File, parentEntityId?: string, spaceId: string = 'default'): Promise<BaseEntityType> {
    console.log('[FileService] Adding file:', { name: file.name, parentId: parentEntityId, spaceId });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('entityType', 'File');

    const response = await fetchService(FILES_ENDPOINTS.add(parentEntityId), {
      method: 'POST',
      body: formData,
    });

    const entity: BaseEntityType = {
      ...response,
      _id: response._id,
      id: response._id,
      name: response.name || file.name,
      type: 'File',
      entityType: 'File',
      created_at: response.created_at || new Date().toISOString(),
      updated_at: response.updated_at || new Date().toISOString(),
      skeleton: {
        '@type': 'File',
        fileId: response._id,
        fileName: file.name,
        groupIds: [],
        mimeType: file.type,
        size: file.size,
      },
      spaceId,
    };

    // Update CRDT and wait for confirmation
    await entityCRDTService.updateEntity(entity._id, entity);

    return entity;
  }

  private async enrichPdfAndWait(fileId: string, pollingInterval = 1000, timeout = 300000): Promise<void> {
    // Start enrichment process
    await fetchService(PDF_ENDPOINTS.enrich(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileId,
      }),
    });

    // Start polling with timeout
    const startTime = Date.now();

    while (true) {
      // Check if we've exceeded timeout
      if (Date.now() - startTime > timeout) {
        throw new Error('PDF enrichment timed out');
      }

      // Get current status
      const status = await this.getPdfEnrichmentStatus(fileId);

      if (status.status === 'completed') {
        // Load the enriched data
        await this.loadPdfEnrichment(fileId);
        break;
      } else if (status.status === 'failed') {
        throw new Error(status.error || 'PDF enrichment failed');
      }

      // Wait before next poll
      await new Promise((resolve) => setTimeout(resolve, pollingInterval));
    }
  }

  async readFile(entityId: string, parentEntityId?: string): Promise<Uint8Array> {
    const maxRetries = 3;
    let lastError: any = null;

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        console.log(`[FileService] Reading file attempt ${attempt + 1}/${maxRetries} for entity ID: ${entityId}`);
        const data = await fetchService(FILES_ENDPOINTS.read(entityId, parentEntityId));

        if (data && data.length > 0) {
          console.log(`[FileService] Successfully read file, size: ${data.length} bytes`);
          return data;
        } else {
          console.warn(`[FileService] Received empty data for file: ${entityId}`);

          // If this isn't the last attempt, try again
          if (attempt < maxRetries - 1) {
            await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, attempt)));
            continue;
          }

          throw new Error('Received empty data from server');
        }
      } catch (error: any) {
        console.error(`[FileService] Error reading file (attempt ${attempt + 1}/${maxRetries}):`, error);
        lastError = error;

        // If this isn't the last attempt, try again with exponential backoff
        if (attempt < maxRetries - 1) {
          const backoffMs = 1000 * Math.pow(2, attempt);
          console.log(`[FileService] Retrying in ${backoffMs}ms...`);
          await new Promise((resolve) => setTimeout(resolve, backoffMs));
        }
      }
    }

    // If we got here, all retries failed
    console.error('[FileService] All attempts to read file failed:', lastError);
    throw lastError || new Error('Failed to read file after multiple attempts');
  }

  async updateFile(file: File, entityId: string, parentEntityId?: string): Promise<Uint8Array> {
    const formData = new FormData();
    formData.append('file', file);

    return fetchService(FILES_ENDPOINTS.update(entityId, parentEntityId), {
      method: 'PUT',
      body: formData,
    });
  }

  async deleteFile(entityId: string, parentEntityId?: string): Promise<Uint8Array> {
    return fetchService(FILES_ENDPOINTS.update(entityId, parentEntityId), {
      method: 'DELETE',
    });
  }

  async getPdfEnrichmentStatus(fileId: string): Promise<EnrichmentStatus> {
    return fetchService(`${PDF_ENDPOINTS.enrich()}/status?fileId=${fileId}`, {
      method: 'GET',
    });
  }

  async loadPdfEnrichment(fileId: string): Promise<PdfEnrichmentResult> {
    const response = await fetchService(`${API_BASE_URL}/inspect_pdf?fileId=${fileId}`, {
      method: 'GET',
    });
    return response.skeleton.enrichments;
  }

  async uploadFile(spaceId: string, file: File): Promise<BaseEntityType> {
    console.log('[FileService] Uploading file:', {
      name: file.name,
      size: file.size,
      type: file.type,
      spaceId,
    });

    const id = uuidv4();
    // Create file entity with all required fields
    const fileEntity: BaseEntityType = {
      _id: id,
      id: id,
      name: file.name,
      type: 'File',
      entityType: 'File',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      skeleton: {
        '@type': 'File',
        path: file.name,
        fileName: file.name,
        size: file.size,
        type: file.type,
      },
    };

    // Update CRDT
    entityCRDTService.updateEntity(fileEntity._id, fileEntity);

    return fileEntity;
  }

  async getRecentFiles(type?: string): Promise<Array<{ id: string; name: string }>> {
    try {
      const response = await fetchService(`${API_BASE_URL}/files/recent${type ? `?type=${type}` : ''}`);
      return response;
    } catch (error) {
      console.error('Error fetching recent files:', error);
      return [];
    }
  }
}

// Create and export the singleton instance
export const fileService = new FileService();

// React hook to use the service
export const useFileService = () => fileService;
