import { API_BASE_URL } from '../api/config';
import type { BaseEntityType } from '../types';
import type { EntityResponse } from '../types/entities';
import type { NoteEntity } from '../types/notes';
import fetchService from './fetchService';

type EntityTypes = 'Note' | 'WebDocument' | 'Space';

interface EntityServiceConfig {
  baseUrl: string;
}

export class EntityService<T extends BaseEntityType> {
  private baseUrl: string;

  constructor(config: EntityServiceConfig) {
    this.baseUrl = config.baseUrl;
  }

  private getAuthHeaders(): Headers {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) throw new Error('No auth token found');

    return new Headers({
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  private async fetchWithAuth(url: string, options: RequestInit = {}) {
    const response = await fetch(url, {
      ...options,
      headers: this.getAuthHeaders(),
      credentials: 'include',
      mode: 'cors',
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return response;
  }

  async create(data: Partial<T>): Promise<T> {
    return fetchService(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
    });
  }

  async get(id: string): Promise<T> {
    return fetchService(`${this.baseUrl}/${id}`, {
      credentials: 'include',
    });
  }

  async update(id: string, data: Partial<T>): Promise<T> {
    return fetchService(`${this.baseUrl}/entities/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      credentials: 'include',
    });
  }

  async fetchEntity(id: string): Promise<EntityResponse> {
    return fetchService(`/api/entities/${id}`);
  }

  async fetchNotes(): Promise<NoteEntity[]> {
    return fetchService('/api/entities?type=AppleNote');
  }

  async fetchEntities(): Promise<T[]> {
    return fetchService(`${this.baseUrl}/entities`, {
      credentials: 'include',
    });
  }

  async deleteEntity(id: string): Promise<boolean> {
    try {
      await fetchService(`${this.baseUrl}/entities/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
      });
      return true;
    } catch (error) {
      if (error instanceof Error && error.message.includes('404')) {
        console.debug('Entity already deleted or not found');
        return false;
      }
      console.error('Delete request failed:', error);
      return false;
    }
  }

  async getEntities(spaceId: string): Promise<BaseEntityType[]> {
    console.log('[EntityService] Fetching entities for space:', spaceId);
    
    try {
      const response = await fetchService(`${API_BASE_URL}/entities`);
      
      if (!Array.isArray(response)) {
        console.error('[EntityService] Expected array response, got:', response);
        throw new Error('Invalid response format from /api/entities');
      }
      
      const entities = response.map((entity: any) => ({
        _id: entity._id,
        id: entity._id,
        name: entity.name ?? 'Untitled',
        type: entity.entityType.toLowerCase(),
        entityType: entity.entityType,
        created_at: entity.created_at || new Date().toISOString(),
        updated_at: entity.updated_at || new Date().toISOString(),
        skeleton: {
          '@type': entity.entityType,
          ...entity.skeleton
        }
      }));

      console.log('[EntityService] Processed entities:', {
        count: entities.length,
        types: [...new Set(entities.map(e => e.entityType))]
      });

      return entities;
    } catch (error) {
      console.error('[EntityService] Failed to fetch entities:', error);
      throw error;
    }
  }

  async getEntitiesByType({ entityType }: { entityType: string }): Promise<BaseEntityType[]> {
    console.log(`[EntityService] Fetching entities of type: ${entityType}`);
    
    try {
      const response = await fetchService(`${API_BASE_URL}/entities?type=${entityType}`);
      
      if (!Array.isArray(response)) {
        console.error('[EntityService] Expected array response, got:', response);
        throw new Error('Invalid response format from /api/entities');
      }
      
      const entities = response.map((entity: any) => ({
        _id: entity._id,
        id: entity._id,
        name: entity.name ?? 'Untitled',
        type: entity.entityType.toLowerCase(),
        entityType: entity.entityType,
        created_at: entity.created_at || new Date().toISOString(),
        updated_at: entity.updated_at || new Date().toISOString(),
        skeleton: entity.skeleton || {},
        appId: entity.appId,
      }));

      console.log(`[EntityService] Found ${entities.length} ${entityType} entities`);
      return entities;
    } catch (error) {
      console.error(`[EntityService] Failed to fetch ${entityType} entities:`, error);
      throw error;
    }
  }
}

// Export a singleton instance
export const entityService = new EntityService({
  baseUrl: process.env.REACT_APP_API_URL || API_BASE_URL
});

// Export the getEntitiesByType function directly
export const getEntitiesByType = (params: { entityType: string }) => {
  return entityService.getEntitiesByType(params);
};

// Usage example:
// export const createEntityService = <T extends BaseEntityType>(entityType: EntityTypes) => {
//   return new EntityService<T>({
//     baseUrl: `