import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSpaceStore from '../stores/spaceStore';

const NewSpacePage = () => {
  const { createSpaceId } = useSpaceStore();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('[NewSpacePage] useEffect');
    const newSpaceId = createSpaceId();
    navigate(`/spaces/${newSpaceId}`, { replace: true });
  }, [createSpaceId, navigate]);

  return <div>Creating new space...</div>;
};

export default NewSpacePage;
