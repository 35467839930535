import type { CursorData } from '../../stores/presenceStore';
import { CRDTService, crdtService } from './crdtService';

export class PresenceCRDTService {
    constructor(private crdt: CRDTService) {}

    observePresence(spaceId: string, onUpdate: (cursors: Record<string, CursorData | null>) => void) {
        return this.crdt.connect<Record<string, CursorData | null>>(`presence-${spaceId}`, (update) => {
            if (update.data) {
                onUpdate(update.data);
            }
        });
    }

    updatePresence(spaceId: string, clientId: string, data: CursorData | null) {
        this.crdt.update(`presence-${spaceId}`, {
            [clientId]: data
        });
    }
}

export const presenceCRDTService = new PresenceCRDTService(crdtService); 