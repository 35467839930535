import './Space.scss';

import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EntityBrowserApp from '../../apps/EntityBrowserApp/EntityBrowserApp';
import NotesApp from '../../apps/NotesApp/components/NotesApp';
import { cleanupNotesStore } from '../../apps/NotesApp/store/notesAppStore';
import { getAllApps, useCustomEventListenerWithReply } from '../../services/actionService';
import type { AIEvent } from '../../services/aiService';
import { aiService } from '../../services/aiService';
import { spaceCRDTService } from '../../services/crdt/spaceCRDTService';
import { useAppRegistryStore } from '../../stores/appRegistryStore';
import { useElectronWindowStore } from '../../stores/electronWindowStore';
import useEntityStore from '../../stores/entityStore';
import type { CursorData } from '../../stores/presenceStore';
import usePresenceStore from '../../stores/presenceStore';
import useSpaceStore from '../../stores/spaceStore';
import type { BaseEntityType } from '../../types/entities';
import type { AnyWindowEntity, WindowEntity } from '../../types/windows';
import { ElectronWindowManager } from '../ElectronWindow/ElectronWindowManager';
import FileDetailsAction, { IFileDetailsAction } from './actions/FileDetailsAction';
import GetAppsAction, { IGetAppsAction } from './actions/GetAppsAction';
import GetExtensionAppsAction, { IGetExtensionAppsAction } from './actions/GetExtensionAppsAction';
import GetFilesAction, { IGetFilesAction } from './actions/GetFilesAction';
import OpenFileAction from './actions/OpenFileAction';
import BlankWindow from './BlankWindow';
import { registerActions } from './definition';
import { createWindowHandlers } from './handlers/windowHandlers';
import { useWindowBehaviors } from './hooks/useWindowBehaviors';
import PrototypeDropZone from './PrototypeDropZone';
import SpaceChat from './SpaceChat/SpaceChat';
import SpaceContents from './SpaceContents';
import SpaceDetailsButton from './SpaceDetailsButton/SpaceDetailsButton';
import SystemMenuButton from './SystemMenuButton/SystemMenuButton';
import TaskBar from './TaskBar';
import {
  fileAndGroupFilter,
  getAppTitle,
  getAppTypeByExt,
  getEntityDisplayName,
  getWindowSizeAndPosition,
  getWindowTitle,
} from './utils/windowUtils';
import Window from './Window';

console.log('[Space] Registering actions...');

registerActions();

interface SpaceProps {
  id: string;
}

const generateWindowId = () => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).slice(2, 8);
  return `window-${timestamp}-${random}`;
};

const Space: React.FC<SpaceProps> = ({ id }) => {
  const {
    activeSpace,
    activeWindowId,
    windows,
    bringWindowToFront,
    setActiveWindow,
    windowZIndexes,
    selectedIds,
    addWindow,
    removeWindow,
    selectWindow,
    updateWindow,
  } = useSpaceStore();

  useEffect(() => {
    console.log('[Space][ActiveWindowId] Active window ID:', activeWindowId);
  }, [activeWindowId]);

  const { entities } = useEntityStore();

  const windowBehaviors = useWindowBehaviors({
    spaceWidth: window.innerWidth,
    spaceHeight: window.innerHeight,
  });

  const [windowIdToAppIdMap, setWindowIdToAppIdMap] = useState<{ [key: string]: string }>({});
  const [isContentsVisible, setIsContentsVisible] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const navigate = useNavigate();
  const clientId = usePresenceStore((state) => state.clientId);
  const setCursor = usePresenceStore((state) => state.setCursor);
  const [cursors, setCursors] = useState<Record<string, CursorData>>({});
  const [isLocalUpdate, setIsLocalUpdate] = useState(false);
  const isUpdatingRef = useRef(false);
  const pendingUpdatesRef = useRef<{
    windows: WindowEntity[];
    settings?: {
      isContentsVisible?: boolean;
      isChatVisible?: boolean;
    };
  } | null>(null);
  const observerRef = useRef<(() => void) | null>(null);

  // Electron window management (rename to avoid conflicts)
  const { addWindow: addElectronWindow, removeWindow: removeElectronWindow } = useElectronWindowStore();

  // Get componentMap from store
  const componentMap = useAppRegistryStore((state) => state.componentMap);

  // Add notesEditorRef at the component level
  const notesEditorRef = useRef(null);

  // First define closeApp
  const closeApp = useCallback(
    (windowId: string) => {
      setActiveWindow(null);
      // Update to directly modify window state
      const windowIds = Object.keys(windows).filter((id) => id !== windowId);
      windowIds.forEach((id) => bringWindowToFront(id));

      setWindowIdToAppIdMap((prev) => {
        const newMap = { ...prev };
        delete newMap[windowId];
        return newMap;
      });
    },
    [setActiveWindow, windows, bringWindowToFront],
  );

  // Then define handleRemoveWindow
  const handleRemoveWindow = useCallback(
    (windowId: string) => {
      // First remove from local state
      removeWindow(windowId);
      // Clean up window management state
      closeApp(windowId);
    },
    [id, removeWindow, windows, closeApp],
  );

  const renderWindowContent = useCallback(
    (window: WindowEntity) => {
      console.log('Rendering window content:', {
        window,
        availableComponents: Object.keys(componentMap),
        component: window.entityId ? componentMap[window.entityId] : null,
      });

      // For blank windows
      if (window.component === 'blank') {
        return <BlankWindow onSelect={window.props?.onSelect} />;
      }

      // For EntityBrowserApp (special case due to props)
      if (window.component === 'EntityBrowserApp') {
        return (
          <EntityBrowserApp
            windowId={window.id}
            spaceId={id}
            title={window.title || 'Entity Browser'}
            onClose={() => handleRemoveWindow(window.id)}
            {...window.props}
          />
        );
      }

      // For Notes app, ensure we have an entity with _id
      if (window.entityId === 'notes') {
        // If no entity exists, create one with the noteId
        const noteEntity = window.entity || {
          _id: window.props?.noteId || `note-${Date.now()}`,
          entityType: 'Note',
          type: 'Note',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };

        return (
          <NotesApp
            ref={notesEditorRef}
            noteId={noteEntity._id}
            windowId={window.id}
            spaceId={id}
            onClose={() => handleRemoveWindow(window.id)}
          />
        );
      }

      // For all other apps, use componentMap
      if (window.entityId) {
        const Component = componentMap[window.entityId];
        if (Component) {
          return (
            <Component
              {...window.props}
              entity={window.entity}
              title={window.title}
              onClose={() => handleRemoveWindow(window.id)}
            />
          );
        }
      }

      console.warn('Unknown window type:', window);
      return <div>Unknown Component</div>;
    },
    [id, handleRemoveWindow, componentMap],
  );

  const { openWebDocument } = useWindowBehaviors({
    spaceWidth: window.innerWidth,
    spaceHeight: window.innerHeight,
  });

  // Handle cursor updates
  useEffect(() => {
    const updateCursors = debounce(() => {
      // TODO: Implement cursor handling with new architecture
      // This will need to be handled by a separate presence service
    }, 50);

    updateCursors();

    return () => {
      updateCursors.cancel();
    };
  }, []);

  // Handle mouse movement
  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setCursor(x, y);
    },
    [setCursor],
  );

  // Modify your existing setIsContentsVisible and setIsChatVisible calls:
  const handleSetContentsVisible = useCallback(
    (visible: boolean) => {
      setIsContentsVisible(visible);
      if (id) {
        spaceCRDTService.updateSettings(id, {
          isContentsVisible: visible,
          isChatVisible,
        });
      }
    },
    [id, isChatVisible],
  );

  const handleSetChatVisible = useCallback(
    (visible: boolean) => {
      setIsChatVisible(visible);
      if (id) {
        spaceCRDTService.updateSettings(id, {
          isContentsVisible,
          isChatVisible: visible,
        });
      }
    },
    [id, isContentsVisible],
  );

  // Window Management
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!id) return;

      windowBehaviors.handleKeyboardShortcuts(event);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [id, windowBehaviors]);

  // Add a helper function to manage window state
  const manageWindowState = useCallback(
    (windowId: string, appId?: string) => {
      const windowIds = Object.keys(windows);
      if (!windowIds.includes(windowId)) {
        bringWindowToFront(windowId);

        if (appId) {
          setWindowIdToAppIdMap((prev) => ({
            ...prev,
            [windowId]: appId,
          }));
        }
      }

      bringWindowToFront(windowId);
    },
    [windows, bringWindowToFront],
  );

  // Modify openApp to handle BrowserView windows
  const openApp = useCallback(
    (appId: string) => {
      if (!id) {
        console.error('Cannot open app - space not initialized');
        return;
      }

      const windowId = generateWindowId();
      const isElectron = !!(window as any).electron?.BrowserView;
      const { size, position } = getWindowSizeAndPosition();

      // Handle Electron browser windows
      if (appId === 'browser' && isElectron) {
        addElectronWindow({
          id: windowId,
          type: 'browserView',
          url: 'https://www.google.com',
          title: getAppTitle(appId),
          bounds: {
            ...position,
            ...size,
          },
          zIndex: 0,
        });
      }
      // Handle component-based windows
      else if (appId === 'EntityBrowserApp') {
        addWindow({
          id: windowId,
          type: 'window',
          component: appId, // Use appId directly as component name
          title: getAppTitle(appId),
          props: {
            windowId,
            spaceId: id,
          },
          position,
          size,
        });
      }
      // Handle PDF viewer
      else if (appId === 'pdfium') {
        addWindow({
          id: windowId,
          type: 'window',
          entityId: 'pdfium',
          title: getAppTitle(appId),
          position,
          size,
          entity: null,
        });
      }
      // Handle other entity-based windows
      else {
        addWindow({
          id: windowId,
          type: 'window',
          position,
          size,
          entityId: appId,
          title: getAppTitle(appId),
        });
      }

      manageWindowState(windowId, appId);
    },
    [addElectronWindow, addWindow, id, manageWindowState],
  );

  useCustomEventListenerWithReply<IGetFilesAction>(GetFilesAction.name, (event, { path }) => {
    const pathParts = path.split('/').filter(Boolean);

    let currentEntities: BaseEntityType[] = entities.filter(fileAndGroupFilter);

    for (let pathPart of pathParts) {
      const nextDir = currentEntities.find(
        (entity) => getEntityDisplayName(entity) === pathPart && entity.entityType === 'Group',
      );

      if (nextDir) {
        currentEntities = nextDir?.children?.filter(fileAndGroupFilter) || [];
      } else {
        return 'Directory not found';
      }
    }

    return currentEntities.map((entity: BaseEntityType) => ({
      type: entity.entityType === 'Group' ? 'directory' : 'file',
      name: getEntityDisplayName(entity),
    }));
  });

  useCustomEventListenerWithReply<IFileDetailsAction>(FileDetailsAction.name, (event, { filePath }) => {
    return entities.find((entity) => filePath.includes(getEntityDisplayName(entity)));
  });

  useEffect(() => {
    const handleOpenFile = (event: CustomEvent) => {
      console.log('[Space] OpenFileAction received:', {
        event,
        detail: event.detail,
        arguments: event.detail?.arguments,
      });

      const { filePath, appName, entityId } = event.detail?.arguments || {};

      if (!appName) {
        console.error('[Space] No appName provided for file:', filePath);
        return;
      }

      const windowId = generateWindowId();
      const { size, position } = getWindowSizeAndPosition();

      const windowConfig: WindowEntity = {
        id: windowId,
        type: 'window' as const,
        position,
        size,
        entityId: appName,
        title: filePath?.split('/').pop() || 'Untitled',
        entity: {
          _id: entityId,
          name: filePath?.split('/').pop() || 'Untitled',
          type: 'File',
          entityType: 'File',
        },
      };

      console.log('[Space] Adding window:', windowConfig);
      addWindow(windowConfig);
    };

    document.addEventListener(OpenFileAction.name, handleOpenFile as EventListener);
    return () => document.removeEventListener(OpenFileAction.name, handleOpenFile as EventListener);
  }, [addWindow]);

  useCustomEventListenerWithReply<IGetExtensionAppsAction>(GetExtensionAppsAction.name, (event, { ext }) => {
    return getAppTypeByExt(ext);
  });

  // Handle window selection (only triggered by clicking the select-handle)
  const handleWindowClick = (windowId: string, event: React.MouseEvent) => {
    console.debug('Window clicked:', {
      windowId,
      metaKey: event.metaKey,
      ctrlKey: event.ctrlKey,
      shiftKey: event.shiftKey,
    });

    event.stopPropagation();
    const isMultiSelect = event.metaKey || event.ctrlKey; // Fix: Use OR operator
    selectWindow(windowId, isMultiSelect);
  };

  // Handle window focus (triggered by clicking anywhere else on the window)
  const handleWindowFocus = (windowId: string) => {
    bringWindowToFront(windowId);
  };

  // Clear selection when clicking space background
  const handleSpaceClick = (event: React.MouseEvent) => {
    // Only clear selection if clicking directly on the space background
    if (event.target === event.currentTarget) {
      selectWindow('', false);
    }
  };

  // Modify memoizedAppContents to use useMemo more effectively
  const memoizedAppContents = useMemo(() => {
    console.debug('[Space] Creating memoized app contents, windows:', windows);

    return Object.values(windows).reduce(
      (acc, entity) => {
        if (entity.type !== 'window') {
          console.debug(`Skipping non-window entity:`, entity);
          return acc;
        }

        // Create a stable key for the content
        const contentKey = `${entity.id}-${entity.entityId}`;
        if (!acc[contentKey]) {
          console.debug(`[Space] Creating content for window ${entity.id}, entityId: ${entity.entityId}`);
          acc[contentKey] = renderWindowContent(entity);
        }
        return acc;
      },
      {} as Record<string, React.ReactNode>,
    );
  }, [windows, renderWindowContent]);

  const windowHandlers = useMemo(
    () =>
      createWindowHandlers({
        addWindow,
        windowManager: {
          bringToFront: bringWindowToFront,
          windows: windows,
        },
        spaceId: id,
        updateWindow: (windowId: string, updates: Partial<AnyWindowEntity>) => {
          useSpaceStore.getState().updateWindow(windowId, updates);
        },
        groupSelected: useSpaceStore.getState().groupSelected,
      }),
    [addWindow, bringWindowToFront, windows, id],
  );

  const handleCreateBlankWindow = useCallback(() => {
    windowHandlers.createBlankWindow();
  }, [windowHandlers]);

  // Add this effect alongside other event listeners
  useEffect(() => {
    const handleOpenNote = (event: Event) => {
      const customEvent = event as CustomEvent<{ noteId: string }>;
      console.debug('Space handling openNote event:', customEvent.detail);

      const windowId = generateWindowId();

      const noteEntity: BaseEntityType = {
        _id: customEvent.detail.noteId,
        id: customEvent.detail.noteId,
        name: 'Note',
        type: 'note',
        entityType: 'Note',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      addWindow({
        id: windowId,
        type: 'window',
        position: { x: 100, y: 100 },
        size: { width: 800, height: 600 },
        entityId: 'notes',
        entity: noteEntity,
      });
      bringWindowToFront(windowId);
    };

    window.addEventListener('openNote', handleOpenNote);
    return () => window.removeEventListener('openNote', handleOpenNote);
  }, [addWindow, bringWindowToFront]);

  // Sync window updates to CRDT
  const handleUpdateWindow = useCallback((windowId: string, updates: Partial<AnyWindowEntity>) => {
    // useSpaceStore.getState().updateWindow(windowId, updates);
    // console.log('[Space] Updating window:', { windowId, updates });
    updateWindow(windowId, updates);
  }, []);

  useEffect(() => {
    const handleOpenWebDocument = (event: Event) => {
      const customEvent = event as CustomEvent<{ entity: BaseEntityType }>;
      const url = (customEvent.detail.entity as any).skeleton.url;
      const { window, windowId } = openWebDocument(url);

      window.entityId = 'webdoc';
      window.entity = customEvent.detail.entity;

      addWindow(window);
      manageWindowState(windowId, 'webdoc');
    };

    window.addEventListener('openWebDocument', handleOpenWebDocument);
    return () => window.removeEventListener('openWebDocument', handleOpenWebDocument);
  }, [openWebDocument, addWindow, manageWindowState]);

  // Add this effect to handle CRDT updates
  // useEffect(() => {
  //   if (!id) return;

  //   const handleSettingsUpdate = (settings: { isContentsVisible?: boolean; isChatVisible?: boolean }) => {
  //     if (settings.isContentsVisible !== undefined) {
  //       setIsContentsVisible(settings.isContentsVisible);
  //     }
  //     if (settings.isChatVisible !== undefined) {
  //       setIsChatVisible(settings.isChatVisible);
  //     }
  //   };

  //   spaceCRDTService.onSettingsUpdate(id, handleSettingsUpdate);
  //   return () => spaceCRDTService.offSettingsUpdate(id, handleSettingsUpdate);
  // }, [id]);

  // const handleGroupWindows = (windowIds: string[]) => {
  //   if (!id) return; // id should be your space ID
  //   spaceService.groupWindows(windowIds, id).catch((error) => console.error('Failed to group windows:', error));
  // };
  //
  // const handleUngroupWindows = (groupId: string) => {
  //   if (!id) return; // id should be your space ID
  //   spaceService.ungroupWindows(groupId, id).catch((error) => console.error('Failed to ungroup windows:', error));
  // };

  // Add effect to log store updates
  useEffect(() => {
    console.debug('Store windows updated:', windows);
  }, [windows]);

  // Add this effect to record window events
  useEffect(() => {
    const recordWindowEvent = (windowId: string, eventType: string, data?: any) => {
      const window = windows[windowId];
      if (!window) return;

      const event: AIEvent = {
        type: eventType,
        window: window,
        entity: window.type === 'window' ? window.entity : undefined,
        data,
        timestamp: Date.now(),
      };

      aiService.recordEvent(event);
    };

    // Record events for each window
    Object.keys(windows).forEach((windowId) => {
      recordWindowEvent(windowId, 'window_opened');
    });

    return () => {
      // Cleanup if needed
    };
  }, [windows]);

  // First, let's fix the window movement effect
  useEffect(() => {
    const handleWindowMove = () => {
      // Force update of all BrowserViews
      document.querySelectorAll('.browser-content').forEach((container) => {
        const event = new CustomEvent('browser-update-bounds');
        container.dispatchEvent(event);
      });
    };

    window.addEventListener('mousemove', handleWindowMove);
    return () => window.removeEventListener('mousemove', handleWindowMove);
  }, []);

  useCustomEventListenerWithReply<IGetAppsAction>(GetAppsAction.name, () => getAllApps());

  // Then fix the active window title rendering
  const activeWindow = activeWindowId ? windows[activeWindowId] : null;

  const handleCloseTab = useCallback(
    (tabId: string, parentId: string) => {
      const parentWindow = windows[parentId];
      if (!parentWindow?.tabs) return;

      if (parentWindow.tabs.length === 2) {
        // If only 2 tabs left, remove tab properties entirely
        handleUpdateWindow(parentId, {
          tabs: undefined,
          activeTabId: undefined,
          isParentWindow: undefined,
        });
      } else {
        // Remove the tab and update active tab if needed
        const newTabs = parentWindow.tabs.filter((id) => id !== tabId);
        handleUpdateWindow(parentId, {
          tabs: newTabs,
          activeTabId: parentWindow.activeTabId === tabId ? newTabs[0] : parentWindow.activeTabId,
        });
        // Also remove the window itself
        handleRemoveWindow(tabId);
      }
    },
    [windows, handleUpdateWindow, handleRemoveWindow],
  );

  const handleDrag = useCallback(
    (windowId: string, x: number, y: number) => {
      handleUpdateWindow(windowId, { position: { x, y } });
    },
    [handleUpdateWindow],
  );

  const renderWindows = useMemo(() => {
    return Object.values(windows)
      .filter((entity) => entity.type === 'window' && (!entity.isTab || entity.isParentWindow))
      .map((entity) => (
        <Window
          key={entity.id}
          id={entity.id}
          entityId={entity.entityId}
          title={getWindowTitle(entity)}
          position={entity.position}
          size={entity.size}
          isSelected={selectedIds.includes(entity.id)}
          onClose={() => handleRemoveWindow(entity.id)}
          onClick={handleWindowClick}
          onFocus={() => handleWindowFocus(entity.id)}
          onDrag={handleDrag}
          onDragStop={(x, y) => handleUpdateWindow(entity.id, { position: { x, y } })}
          onResizeStop={(width, height) => handleUpdateWindow(entity.id, { size: { width, height } })}
          zIndex={windowZIndexes[entity.id] || 0}
          isFocused={activeWindowId === entity.id}
          onAddWindow={() => windowHandlers.addTab(entity.id)}
          tabs={entity.tabs}
          activeTabId={entity.activeTabId}
          onActivateTab={(tabId) => {
            handleUpdateWindow(entity.id, {
              activeTabId: tabId,
            });
          }}
          onCloseTab={(tabId) => handleCloseTab(tabId, entity.id)}
          tabContents={memoizedAppContents}
        >
          {memoizedAppContents[`${entity.id}-${entity.entityId}`]}
        </Window>
      ));
  }, [
    windows,
    windowZIndexes,
    selectedIds,
    activeWindowId,
    handleWindowClick,
    handleWindowFocus,
    memoizedAppContents,
    handleRemoveWindow,
    handleUpdateWindow,
    handleDrag,
    windowHandlers,
    handleCloseTab,
  ]);

  const createEntityBrowserWindow = (spaceId: string) => {
    const windowId = generateWindowId();

    addWindow({
      id: windowId,
      type: 'window',
      component: 'EntityBrowser',
      title: 'Entity Browser',
      props: {
        windowId,
        spaceId,
      },
      position: { x: 100, y: 100 },
      size: { width: 400, height: 600 },
    });

    return windowId;
  };

  // const handleCreateNote = () => {
  //   console.log('[Space] Creating Notes window');
  //   const windowId = generateWindowId();
  //   addWindow({
  //     id: windowId,
  //     type: 'window',
  //     position: { x: 100, y: 100 },
  //     size: { width: 800, height: 600 },
  //     entityId: 'notes',
  //   });
  //   bringWindowToFront(windowId);
  // };

  useEffect(() => {
    return () => {
      // Make sure to cleanup notes store when Space unmounts
      cleanupNotesStore('window-1', id);
    };
  }, [id]);

  return (
    <div className="desktop">
      <div
        className="space-container"
        onClick={handleSpaceClick}
        onMouseMove={handleMouseMove}
        style={{ backgroundColor: activeSpace?.settings?.bgColor || '#ffffff00' }}
      >
        <span className="spaceTitle">
          <SystemMenuButton />
          <SpaceDetailsButton />
          {activeWindow && <span className="activeWindowTitle">{getWindowTitle(activeWindow)}</span>}
        </span>
        {Object.entries(cursors)
          .filter(([key]) => key !== clientId)
          .map(([key, cursor]) =>
            cursor ? (
              <div
                key={key}
                className="cursor"
                style={{
                  position: 'absolute',
                  zIndex: '99999',
                  left: `${cursor.x}px`,
                  top: `${cursor.y}px`,
                  backgroundColor: 'blue',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  pointerEvents: 'none',
                  fontSize: '8px',
                }}
              >
                🌺{id}
              </div>
            ) : null,
          )}
        {renderWindows}

        <SpaceContents
          isVisible={isContentsVisible}
          setIsVisible={handleSetContentsVisible}
          entities={windows}
          selectedIds={selectedIds}
          handleWindowClick={handleWindowClick}
        />

        <SpaceChat isVisible={isChatVisible} setIsVisible={handleSetChatVisible} />

        <TaskBar onOpenApp={openApp} onCreateBlankWindow={handleCreateBlankWindow} />

        {/* Only render WindowManager in Electron environment */}
        {!!(window as any).electron?.BrowserView && <ElectronWindowManager />}
      </div>
      <PrototypeDropZone />
    </div>
  );
};

export default Space;
