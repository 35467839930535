import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DebuggerPanel } from '../components/Debug/DebuggerPanel';
import Space from '../components/Space/Space';
import { spaceCRDTService } from '../services/crdt/spaceCRDTService';
import useEntityStore from '../stores/entityStore';
import useSpaceStore from '../stores/spaceStore';
import { useAppRegistryStore } from '../stores/appRegistryStore';
import { appRegistry } from '../services/appRegistryService';
import { isLocalhost } from '../utils/locationUtils';
import { useAuth0 } from '@auth0/auth0-react';

const SpaceWrapperPage = () => {
  const { showDebugger } = useSpaceStore();

  const { getAccessTokenSilently, logout } = useAuth0();

  const { spaceId } = useParams();
  const navigate = useNavigate();
  const { initializeApps } = useAppRegistryStore();
  const { getSpaceStatus, initialize } = useSpaceStore();

  useEffect(() => {
    console.log('[SpaceWrapper] Component mounted');

    const init = async () => {
      // const token = localStorage.getItem('authToken');
      const token = await getAccessTokenSilently();

      if (token) {
        localStorage.setItem('authToken', token);

        try {
          await appRegistry.initializeSystemApps();
          console.debug('[SpaceWrapper] System apps initialized');

          await initializeApps();
          console.debug('[SpaceWrapper] Apps loaded into store');
        } catch (error) {
          console.error('[SpaceWrapper] Failed to initialize:', error);
          localStorage.removeItem('authToken');
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
      }
    };

    init();

    return () => {
      console.log('[App] Component unmounted');
    };
  }, [getAccessTokenSilently, initializeApps, logout]);

  useEffect(() => {
    console.log('[SpaceWrapper] useEffect', { spaceId });

    const init = async () => {
      const currentStatus = await getSpaceStatus();
      if (currentStatus === 'idle') {
        try {
          await initialize(spaceId!);
        } catch (error) {
          console.error('Failed to initialize space:', error);
          navigate('/new');
        }
      }
    };

    init();

    return () => {
      console.log('[SpaceWrapper] Cleaning up');
      useEntityStore.getState().cleanup();
      spaceCRDTService.disconnectAll();
    };
  }, [getSpaceStatus, initialize, navigate, spaceId]);

  return spaceId ? (
    <>
      <Space id={spaceId} />
      {showDebugger && isLocalhost() ? <DebuggerPanel /> : null}
    </>
  ) : null;
};

export default SpaceWrapperPage;
