export default async function fetchService(url: string, init?: RequestInit): Promise<any> {
  const authToken = localStorage.getItem('authToken');

  try {
    const response = await fetch(url, {
      ...init,
      headers: {
        // Only add Content-Type for non-FormData requests that have a body
        ...(init?.body && !(init.body instanceof FormData) 
          ? { 'Content-Type': 'application/json' } 
          : {}),
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        ...init?.headers,
      },
      credentials: 'include',
    });

    if (!response.ok) {
      // Create a custom error with the response attached
      const error: any = new Error(`Request failed with status: ${response.status}`);
      error.status = response.status;
      error.statusText = response.statusText;
      error.response = response.clone(); // Clone so we can still read it later

      // First try to get error as JSON
      try {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const errorData = await response.json();
          error.data = errorData;
          
          if (errorData.error) {
            error.message = `${error.message}. ${errorData.error}`;
            if (errorData.details) {
              error.message += `: ${errorData.details}`;
            }
          }
        } else {
          // If not JSON, use text
          const errorText = await response.text();
          if (errorText) {
            error.data = errorText;
            error.message = `${error.message}. ${errorText}`;
          }
        }
      } catch (e) {
        // If parsing fails, keep the original error
        console.warn('Error parsing response body:', e);
      }
      
      throw error;
    }

    const contentType = response.headers.get('Content-Type');

    if (contentType?.includes('application/json')) {
      return response.json();
    }

    if (contentType?.includes('application/octet-stream') || 
        contentType?.toLowerCase()?.includes('pdf') ||
        contentType?.toLowerCase()?.includes('image/')) {
      const arrayBuffer = await response.arrayBuffer();
      return new Uint8Array(arrayBuffer);
    }

    return response.text();
  } catch (error) {
    console.error(`📱 Fetch error for ${url}:`, error);
    throw error;
  }
}
