import './ModelSelector.scss';

import React, { useEffect,useRef, useState } from 'react';

import { CustomModelIcon } from './CustomModelIcon';

export type ModelType = 
  | 'claude-3.5-sonnet'
  | 'claude-3.7-sonnet-thinking'
  | 'gpt-4o'
  | 'gpt-4.5-preview'
  | 'bedrock-small'
  | CustomModelType;

export const AVAILABLE_MODELS: ModelType[] = [
  'claude-3.5-sonnet',
  'claude-3.7-sonnet-thinking',
  'gpt-4o',
  'gpt-4.5-preview',
  'bedrock-small'
];

// Add custom model types
export type CustomModelType = 
  | 'seed-fundraising'
  | 'my-fiction-writing'
  | 'webmd-notes'
  | 'mastermind';

export const CUSTOM_MODELS: { id: CustomModelType; name: string }[] = [
  { id: 'seed-fundraising', name: 'Seed Fundraising' },
  { id: 'my-fiction-writing', name: 'My Fiction Writing' },
  { id: 'webmd-notes', name: 'WebMD + Notes' },
  { id: 'mastermind', name: 'Mastermind' }
];

interface ModelSelectorProps {
  model: ModelType;
  onModelChange: (model: ModelType) => void;
  disabled?: boolean;
}

export const ModelSelector: React.FC<ModelSelectorProps> = ({
  model,
  onModelChange,
  disabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      // Check if click is outside both button and dropdown
      if (
        buttonRef.current && 
        dropdownRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const handleModelChange = (newModel: ModelType) => {
    console.log('Button clicked!');
    console.log('ModelSelector handling click:', newModel);
    onModelChange(newModel);
    setIsOpen(false);
  };

  return (
    <div className="model-selector">
      <button 
        ref={buttonRef}
        type="button" 
        className="action-button model-button"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        disabled={disabled}
      >
        {model}
      </button>
      {isOpen && (
        <div 
          ref={dropdownRef}
          className="model-dropdown"
          onClick={(e) => e.stopPropagation()}
        >
          {AVAILABLE_MODELS.map((modelOption) => (
            <button
              key={modelOption}
              type="button"
              className={`model-option ${model === modelOption ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleModelChange(modelOption);
              }}
            >
              {modelOption}
              {model === modelOption && <span className="check">✓</span>}
            </button>
          ))}
          
          <div className="model-section-header">My Models</div>
          
          {CUSTOM_MODELS.map(({ id, name }) => (
            <button
              key={id}
              type="button"
              className={`model-option custom ${model === id ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleModelChange(id as ModelType);
              }}
            >
              <CustomModelIcon />
              <span>{name}</span>
              {model === id && <span className="check">✓</span>}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}; 