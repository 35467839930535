import React from 'react';

interface ChevronProps {
  isOpen?: boolean;
}

export const Chevron: React.FC<ChevronProps> = ({ isOpen = false }) => {
  return (
    <svg 
      className={`chevron ${isOpen ? 'open' : ''}`}  
      width="10" 
      height="6" 
      viewBox="0 0 10 6" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={{ 
        transform: isOpen ? 'none' : 'rotate(180deg)',
        transition: 'transform 0.2s ease'
      }}
    >
      <path 
        d="M5.03711 0.0151367C4.90104 0.0151367 4.7793 0.0670573 4.67188 0.170898L0.514648 4.4248C0.468099 4.47135 0.432292 4.52327 0.407227 4.58057C0.382161 4.64144 0.369629 4.70589 0.369629 4.77393C0.369629 4.86702 0.391113 4.95117 0.434082 5.02637C0.477051 5.10156 0.534342 5.16064 0.605957 5.20361C0.681152 5.24658 0.765299 5.26807 0.858398 5.26807C0.994466 5.26807 1.11084 5.22152 1.20752 5.12842L5.32178 0.922852H4.74707L8.86133 5.12842C8.96159 5.22152 9.07796 5.26807 9.21045 5.26807C9.30355 5.26807 9.3859 5.24658 9.45752 5.20361C9.53271 5.16064 9.5918 5.10156 9.63477 5.02637C9.67773 4.95117 9.69922 4.86702 9.69922 4.77393C9.69922 4.64144 9.65088 4.52686 9.5542 4.43018L5.39697 0.170898C5.35042 0.120768 5.29492 0.0813802 5.23047 0.0527344C5.1696 0.0276693 5.10514 0.0151367 5.03711 0.0151367Z" 
        fill="currentColor"
      />
    </svg>
  );
}; 