import React, { useEffect,useRef, useState } from 'react';

import { AnyWindowEntity } from '../../../../../types/windows';
import { ChatMode } from '../../types';
import { MentionSuggestions } from '../MentionSuggestions';
import { ModelSelector, ModelType } from '../ModelSelector';

interface ChatInputProps {
  inputText: string;
  isProcessing: boolean;
  mode: ChatMode;
  mentionSuggestions: AnyWindowEntity[];
  model: ModelType;
  onModelChange: (model: ModelType) => void;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  onMentionSelect: (entity: AnyWindowEntity) => void;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  inputText,
  isProcessing,
  mode,
  mentionSuggestions,
  model,
  onModelChange,
  onInputChange,
  onSubmit,
  onMentionSelect
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const modelButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!isModelDropdownOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modelButtonRef.current && 
        !modelButtonRef.current.contains(event.target as Node)
      ) {
        setIsModelDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isModelDropdownOpen]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <form onSubmit={onSubmit} className="chat-input">
      <div ref={inputWrapperRef} className="input-wrapper">
        <textarea
          ref={inputRef}
          value={inputText}
          onKeyDown={handleKeyDown}
          onChange={onInputChange}
          placeholder={
            isProcessing 
              ? 'Processing...' 
              : mode === 'create'
                ? 'Create and edit stuff, @ to mention'
                : mode === 'editor'
                  ? 'Describe the edit you want to make...'
                  : mode === 'exp1'
                    ? 'Experimental mode 1'
                    : 'Ask anything, @ to mention'
          }
          disabled={isProcessing}
          rows={1}
          onInput={(e) => {
            const target = e.target as HTMLTextAreaElement;
            target.style.height = 'auto';
            target.style.height = `${Math.min(target.scrollHeight, 200)}px`;
          }}
        />
        
        <div className="input-actions">
          <ModelSelector
            model={model}
            onModelChange={onModelChange}
            disabled={isProcessing}
          />
          <button type="submit" disabled={isProcessing} className="chatInputButton">
            {isProcessing ? '⏳' : 'Send ↵'}
          </button>
        </div>

        {mentionSuggestions && mentionSuggestions.length > 0 && (
          <MentionSuggestions
            suggestions={mentionSuggestions}
            onSelect={onMentionSelect}
            style={{
              width: inputWrapperRef.current?.offsetWidth,
            }}
          />
        )}
      </div>
    </form>
  );
}; 