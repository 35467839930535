import './SpaceContents.scss';

import React from 'react';

import type { AnyWindowEntity } from '../../types/windows';
import { getAppTitle } from './utils/windowUtils';

interface SpaceContentsProps {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  entities: Record<string, AnyWindowEntity>;
  selectedIds: string[];
  handleWindowClick: (windowId: string, event: React.MouseEvent) => void;
}

const SpaceContents: React.FC<SpaceContentsProps> = ({
  isVisible,
  setIsVisible,
  entities,
  selectedIds,
  handleWindowClick,
}) => {
  return (
    <>
      <div className="contents-wrapper">
        <button className="contents-button" onClick={() => setIsVisible(!isVisible)}>
          ☰
        </button>
      </div>

      {isVisible && (
        <div className="contents-panel">
          <h3>Space Contents</h3>
          <ul>
            {Object.values(entities).map((entity) => {
              if (entity.type === 'window') {
                const title = entity.tabs 
                  ? `Window Group (${entity.tabs.length} tabs)` 
                  : getAppTitle(entity.entityId || '');

                return (
                  <li
                    key={entity.id}
                    className={selectedIds.includes(entity.id) ? 'selected' : ''}
                    onClick={(e) => handleWindowClick(entity.id, e)}
                  >
                    <span>{title}</span>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default SpaceContents;
